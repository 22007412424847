import React, { useState, useEffect, useRef } from "react";
import { CgTag } from "react-icons/cg";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridValueGetterParams,
  useGridApiEventHandler,
  useGridApiRef,
} from "@mui/x-data-grid";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FiRefreshCcw, FiEdit } from "react-icons/fi";
import { v4 as uuid } from "uuid";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { QueryLoading } from "./QueryStatus";
import { animated, useSpring } from "@react-spring/web";
import { FadeIn } from "../components/Animations";
import LoanDetail from "../components/LoanDetail";

export default function ClientList() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [loans, setLoans] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setOpenLoading(true);
      setTimeout(() => {
        setOpenLoading(false);
      }, 10000);
      const response = await axiosPrivate.get("/clients");
      //      console.log("Clients:", response.data);
      setClients(response.data);
      setOpenLoading(false);
    }
    fetchData();
  }, []);

  const EditClick = async (params) => {
    console.log("EDIT CLICK", params);
    let rowData = params.row;
    let response;

    response = await axiosPrivate.get(`/clients/bankDetails/${rowData.CIFKEY}`);
    let bankDetail = response.data[0];

    response = await axiosPrivate.get(`/clients/details/${rowData.CIFKEY}`);
    let clientDetail = response.data[0];

    response = await axiosPrivate.get(`/departments/${rowData.DeptCom}`);
    let department = response.data.length ? response.data[0].DeptName : null;
    console.log("department", department);

    let clientData = Object.assign({}, clientDetail, bankDetail, rowData, {
      DeptName: department,
    });

    console.log("Passing clientData", clientData);
    navigate("/addClient", { state: clientData });
  };

  const client_columns: GridColDef[] = [
    {
      field: "action",
      headerName: " ",
      flex: 1,
      //      renderCell: (params) => <CgTag onClick={handleOpen(params)} />,
      renderCell: (params) => <FiEdit onClick={() => EditClick(params)} />,
    },
    {
      field: "ClientName",
      headerName: "Client Name",
      flex: 10,
      editable: false,
    },
    { field: "CIFKEY", headerName: "CIFKEY", flex: 7 },
    { field: "DeptCom", headerName: "DeptCom", flex: 4 },
    { field: "Title", headerName: "Title", flex: 4 },
    { field: "SPNO", headerName: "SPNO", flex: 4 },
    { field: "SPPE", headerName: "SPPE", flex: 7 },
    { field: "SPVA", headerName: "SPVA", flex: 7 },
    { field: "Location", headerName: "Location", flex: 6 },
    {
      field: "CreationDate",
      headerName: "Creation Date",
      flex: 7,
      editable: false,
    },
  ];

  const loans_columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "TRANSACTIONID",
      flex: 6,
      editable: true,
    },
    { field: "CIFKEY", headerName: "CIFKEY", flex: 7, editable: true },
    { field: "Code", headerName: "Code", flex: 4, editable: true },
    { field: "LoanDate", headerName: "Loan Date", flex: 7, editable: true },
    {
      field: "LoanAmount",
      headerName: "Loan Amount",
      flex: 7,
      editable: true,
    },
    { field: "Offset", headerName: "Offset", flex: 7, editable: true },
    { field: "NetLoan", headerName: "NetLoan", flex: 7, editable: true },
    { field: "NOF", headerName: "NOF", flex: 5, editable: true },
    { field: "Interest", headerName: "Interest", flex: 7, editable: true },
    { field: "Repayable", headerName: "Repayable", flex: 7, editable: true },
    { field: "Deduction", headerName: "Deduction", flex: 7, editable: true },
  ];

  const handleOnRowClick = async (rowData) => {
    console.log("Clicked ->" + rowData.ClientName);
    setSelectedRow(rowData);
    //Reset loan details
    setSelectedLoan(null);
    let response = await axiosPrivate.get(`/loans/${rowData.CIFKEY}`);

    console.log("Loan:", response.data);
    setLoans(response.data);
  };

  const handleOnLoanClick = async (rowData) => {
    console.log("Selected Loan ->" + rowData);
    setSelectedLoan(rowData);
  };

  async function doRefetch() {
    const response = await axiosPrivate.get("/clients");
    console.log("Clients:", response.data);
    setClients(response.data);
  }

  function addClient() {
    navigate("/addClient");
  }

  function addLoan() {
    navigate("/addLoan", { state: { ...selectedRow } });
  }

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <Dialog open={openLoading}>
        <DialogContent>
          <QueryLoading />
        </DialogContent>
      </Dialog>

      <div
        className="d-flex flex-row justify-content-between"
        style={{ width: "95%" }}
      >
        <h2>
          Clients
          <span>
            <Button onClick={doRefetch}>
              <FiRefreshCcw />
            </Button>
          </span>
        </h2>
        <Button variant="contained" className="mx-5 my-2" onClick={addClient}>
          Add New Client
        </Button>
      </div>
      <Box sx={{ height: selectedRow ? 350 : 700, width: "95%" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "CreationDate", sort: "desc" }],
            },
          }}
          onRowClick={(param) => handleOnRowClick(param.row)}
          rowHeight={25}
          rows={clients}
          getRowId={(row) => uuid()}
          columns={client_columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />

        <FadeIn isVisible={selectedRow}>
          <div className="d-flex flex-row justify-content-between">
            <div className="mt-2 d-flex flex-row align-items-baseline">
              <h3>Loans </h3> -{" "}
              <h6 className=" text-warning">{selectedRow?.ClientName}</h6>
            </div>
            <Button
              size="small"
              className="mx-5 my-2"
              variant="contained"
              onClick={addLoan}
            >
              Add Loan
            </Button>
          </div>
          <DataGrid
            rowHeight={25}
            rows={loans}
            getRowId={(row) => uuid()}
            onRowClick={(param) => handleOnLoanClick(param.row)}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            columns={loans_columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 700 },
              },
            }}
          />
        </FadeIn>
        {selectedLoan && (
          <FadeIn isVisible={selectedLoan}>
            <LoanDetail loanData={selectedLoan} refetch={doRefetch} />
          </FadeIn>
        )}
      </Box>
    </Stack>
  );
}
