import { TextField } from "@mui/material";
import bootstrap from "bootstrap/dist/js/bootstrap.min";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export function AddBranch({ bankCode, refreshBranch }) {
  const axiosPrivate = useAxiosPrivate();
  const modalRef = useRef();
  const [branchName, setBranchName] = useState("");
  const [branchCode, setBranchCode] = useState("");
  const [xcode, setXcode] = useState("");
  const [bankBranchCode, setBankBranchCode] = useState("");

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new bootstrap.Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrap.Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const submitBranch = async (e) => {
    e.preventDefault();

    console.log(bankCode, branchName, branchCode, xcode, bankBranchCode);

    let submitData = {
      BranchName: branchName,
      BankCode: bankCode,
      BranchCode: branchCode,
      Xcode: xcode,
      BankBranchCode: bankBranchCode,
    };

    const response = await axiosPrivate.post("/banks/addBranch", submitData);
    console.log("Add Branch Returned:", response);
    setBranchName("");
    setBranchCode("");
    setXcode("");
    setBankBranchCode("");
    refreshBranch(bankCode);
    hideModal();
  };

  return (
    <div className="addEmployee">
      <button type="button" className="btn btn-primary" onClick={showModal}>
        Add Branch
      </button>
      <div className="modal fade" ref={modalRef} tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add New Bank
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <section>
                <TextField
                  id="BankCode"
                  name="BankCode"
                  label="Bank Code"
                  aria-readonly
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={bankCode}
                />
                <TextField
                  id="BranchName"
                  name="BranchName"
                  label="Branch Name"
                  required
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={branchName}
                  onChange={(e) => {
                    setBranchName(e.target.value);
                  }}
                />
                <TextField
                  id="BranchCode"
                  name="BranchCode"
                  label="Branch Code"
                  required
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={branchCode}
                  onChange={(e) => {
                    setBranchCode(e.target.value);
                    setBankBranchCode(bankCode + e.target.value);
                  }}
                />
                <TextField
                  id="XCode"
                  name="Xcode"
                  label="X Code"
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={xcode}
                  onChange={(e) => setXcode(e.target.value)}
                />
                <TextField
                  id="BankBranchCode"
                  name="BankBranchCode"
                  label="Bank Branch Code"
                  aria-readonly
                  required
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={bankBranchCode}
                />
              </section>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitBranch}
                disabled={
                  !bankCode.length || !branchName.length || !branchCode.length
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
