import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogContent,
  Paper,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AnalyticCard from "../components/AnalyticCard";
import { FiRefreshCcw, FiEdit } from "react-icons/fi";
import { QueryError, QueryLoading } from "./QueryStatus";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { blueGrey } from "@mui/material/colors";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";

function Dashboard() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = useState(false);

  const [dashboardData, setDashboardData] = useState({});
  const [clientsChange, setClientsChange] = useState(0);
  const [loanChange, setLoanChange] = useState(0);
  const [interestChange, setInterestChange] = useState(0);
  const [repaymentChange, setRepaymentChange] = useState(0);
  const [chartData, setChartData] = useState({
    height: 300,
    type: "line",
    options: {
      chart: {
        id: "area",
        type: "area",
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      xaxis: {
        type: "category",
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
      },
      colors: ["#2E93fA", "#66DA26", "#546E7A", "#E91E63", "#FF9800"],
      legend: {
        show: true,
        fontSize: "14px",
        fontFamily: `'Roboto', sans-serif`,
        position: "bottom",
        offsetX: 20,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
    },
    series: [
      {
        name: "Repayment",
        data: [],
      },
    ],
  });

  useEffect(() => {
    // do not load chart when loading
    ApexCharts.exec("area", "updateOptions", chartData);
  }, [chartData]);

  useEffect(() => {
    async function fetchData() {
      setOpenLoading(true);
      const response = await axiosPrivate.get("/dashboard");
      setOpenLoading(false);
      console.log("Dashboard data:", response.data);
      setDashboardData(response.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!dashboardData) return undefined;

    if (!dashboardData.ClientsLastWeek) {
      if (dashboardData.ClientsThisWeek) {
        setClientsChange(100);
      } else {
        setClientsChange(0);
      }
    } else {
      if (!dashboardData.ClientsThisWeek) {
        setClientsChange(-100);
      } else {
        setClientsChange(
          (
            ((dashboardData.ClientsThisWeek - dashboardData.ClientsLastWeek) /
              dashboardData.ClientsLastWeek) *
            100
          ).toFixed(2)
        );
      }
    }

    if (!dashboardData.LoanTotalLastWeek) {
      if (dashboardData.LoanTotalThisWeek) {
        setLoanChange(100);
      } else {
        setLoanChange(0);
      }
    } else {
      if (!dashboardData.LoanTotalThisWeek) {
        setLoanChange(-100);
      } else {
        setLoanChange(
          (
            ((dashboardData.LoanTotalThisWeek -
              dashboardData.LoanTotalLastWeek) /
              dashboardData.LoanTotalLastWeek) *
            100
          ).toFixed(2)
        );
      }
    }

    if (!dashboardData.InterestTotalLastWeek) {
      if (dashboardData.InterestTotalThisWeek) {
        setInterestChange(100);
      } else {
        setInterestChange(0);
      }
    } else {
      if (!dashboardData.InterestTotalThisWeek) {
        setInterestChange(-100);
      } else {
        setInterestChange(
          (
            ((dashboardData.InterestTotalThisWeek -
              dashboardData.InterestTotalLastWeek) /
              dashboardData.InterestTotalLastWeek) *
            100
          ).toFixed(2)
        );
      }
    }

    if (!dashboardData.RepaymentTotalLastWeek) {
      if (dashboardData.RepaymentTotalThisWeek) {
        setRepaymentChange(100);
      } else {
        setRepaymentChange(0);
      }
    } else {
      if (!dashboardData.RepaymentTotalThisWeek) {
        setRepaymentChange(-100);
      } else {
        setRepaymentChange(
          (
            ((dashboardData.RepaymentTotalThisWeek -
              dashboardData.RepaymentTotalLastWeek) /
              dashboardData.RepaymentTotalLastWeek) *
            100
          ).toFixed(2)
        );
      }
    }
  }, [dashboardData]);

  useEffect(() => {
    if (!dashboardData || Object.keys(dashboardData).length === 0)
      return undefined;

    console.log("DASHBOARD Data from useEffect", dashboardData);

    setChartData((prevData) => ({
      ...prevData,
      xaxis: {
        type: "PNO",
        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: true,
        },
        categories: dashboardData.RepaymentEachPNO.map((obj) => obj.PayNo),
      },
    }));

    setChartData((prevData) => ({
      ...prevData,
      series: [
        {
          name: "Total Repaymenmts",
          data: dashboardData.RepaymentEachPNO.map((obj) =>
            obj.TotalRepayment.toFixed(2)
          ),
        },
      ],
    }));
  }, [dashboardData]);

  console.log("Dashboard data:", dashboardData);

  async function doRefetch() {
    console.log("Doing refetch...");
    const response = await axiosPrivate.get("/dashboard");
    console.log("Dashboard data:", response.data);
    setDashboardData(response.data);
  }

  const defaultDetails = (defaulter) => {
    console.log("Cliecked item", defaultDetails);
    navigate("/default", { state: defaulter });
  };

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <Dialog open={openLoading}>
        <DialogContent>
          <QueryLoading />
        </DialogContent>
      </Dialog>

      <h2>
        Dashboard
        <span>
          <Button onClick={doRefetch}>
            <FiRefreshCcw />
          </Button>
        </span>
      </h2>
      <div className="d-flex flex-row gap-2">
        <AnalyticCard
          color="linear-gradient(45deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)"
          title="Clients This Week"
          count={dashboardData?.ClientsThisWeek}
          percentage={clientsChange}
          extra={
            dashboardData.ClientsThisWeek - dashboardData.ClientsLastWeek
              ? dashboardData.ClientsLastWeek
              : 0
          }
        />
        <AnalyticCard
          color="linear-gradient(45deg, rgba(178,174,238,1) 0%, rgba(148,233,158,1) 100%)"
          title="Total Loan This Week"
          currency="K"
          count={
            dashboardData?.LoanTotalThisWeek
              ? dashboardData?.LoanTotalThisWeek
              : 0
          }
          percentage={loanChange}
          extra={
            parseInt(dashboardData.LoanTotalThisWeek) -
            parseInt(
              dashboardData.LoanTotalLastWeek
                ? dashboardData.LoanTotalLastWeek
                : 0
            )
          }
        />
        <AnalyticCard
          color="linear-gradient(45deg, rgba(174,238,237,1) 0%, rgba(233,148,148,1) 100%)"
          title="Total Repayment This PNO"
          currency="K"
          count={dashboardData?.RepaymentTotalThisWeek}
          percentage={repaymentChange}
          extra={
            parseInt(dashboardData.RepaymentTotalThisWeek) -
            parseInt(
              dashboardData.RepaymentTotalLastWeek
                ? dashboardData.RepaymentTotalLastWeek
                : 0
            )
          }
          sinceText="since last PNO"
        />
      </div>
      <div className="mt-2 d-flex gap-2 flex-row" style={{ width: "95%" }}>
        <Card>
          <CardMedia sx={{ padding: 0 }}>
            <div
              style={{
                background:
                  "linear-gradient(270deg, rgba(122,173,217,0.9052214635854342) 5%, rgba(0,241,255,1) 100%)",
                paddingLeft: 10,
                paddingRight: 60,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <span style={{ fontSize: "16px" }}>
                Total Repayments per PNO (Last 10 PNOs)
              </span>
            </div>
          </CardMedia>
          <CardContent sx={{ padding: 1 }}>
            <div>
              <ReactApexChart
                {...chartData}
                id="chart"
                type="area"
                height={400}
              />
            </div>
          </CardContent>
        </Card>
        <Card sx={{ height: "100%", padding: 0 }} variant="outlined">
          <CardMedia sx={{ padding: 0 }}>
            <div
              style={{
                background:
                  "linear-gradient(270deg, rgba(122,173,217,0.9052214635854342) 5%, rgba(0,241,255,1) 100%)",
                paddingLeft: 10,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <span style={{ fontSize: "16px" }}>
                Top 10 default departments
              </span>
            </div>
          </CardMedia>
          <CardContent sx={{ padding: 1 }}>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Department</th>
                  <th scope="col">Total Debt</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(dashboardData).length !== 0 &&
                  dashboardData.TopDefaulters.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td className=" align-items-md-start">
                          <Button
                            variant="text"
                            size="small"
                            style={{ textTransform: "unset", marginTop: -2 }}
                            onClick={(e) => {
                              defaultDetails(item);
                            }}
                            // type="button"
                            // class="btn btn-link btn-sm"
                          >
                            {item.DeptName}
                          </Button>
                        </td>
                        <td>{item.OutstandingDebt}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </CardContent>
        </Card>
      </div>
    </Stack>
  );
}

export default Dashboard;
