import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { FiEdit, FiRefreshCcw } from "react-icons/fi";
import LoanDetail from "../components/LoanDetail";
import { QueryError, QueryLoading } from "./QueryStatus";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { FadeIn } from "../components/Animations";

export default function LoanList() {
  const axiosPrivate = useAxiosPrivate();
  const [allLoans, setAllLoans] = useState({});
  const [selectedRow, setSelectedRow] = useState([]);

  const [selectedId, setSelectedId] = useState(null);
  const [showActiveLoansOnly, setShowActiveLoansOnly] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const handleShowChange = (event) => {
    setShowActiveLoansOnly(event.target.checked);
  };
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setOpenLoading(true);
      setTimeout(() => {
        setOpenLoading(false);
      }, 10000);
      const response = await axiosPrivate.get("/loans");
      //      console.log("Fetching Loans:", response.data);
      setAllLoans(response.data);
      setOpenLoading(false);
    }
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: " ",
      flex: 1,
      //      renderCell: (params) => <CgTag onClick={handleOpen(params)} />,
      renderCell: (params) => <FiEdit onClick={() => EditClick(params)} />,
    },
    {
      field: "TRANSACTIONID",
      headerName: "TRANSACTIONID",
      flex: 7,
      editable: true,
    },
    { field: "CIFKEY", headerName: "CIFKEY", flex: 7, editable: true },
    { field: "ClientName", headerName: "Client Name", flex: 8, editable: true },
    { field: "LoanDate", headerName: "Loan Date", flex: 8, editable: true },
    {
      field: "LoanAmount",
      headerName: "Loan Amount",
      flex: 7,
      editable: true,
    },
    { field: "Offset", headerName: "Offset", flex: 7, editable: true },
    { field: "NetLoan", headerName: "NetLoan", flex: 7, editable: true },
    { field: "NOF", headerName: "NOF", flex: 4, editable: true },
    { field: "Interest", headerName: "Interest", flex: 7, editable: true },
    { field: "Repayable", headerName: "Repayable", flex: 7, editable: true },
    { field: "Deduction", headerName: "Deduction", flex: 7, editable: true },
    { field: "AcountStatus", headerName: "Status", flex: 4, editable: true },
  ];

  const EditClick = async (params) => {
    console.log("EDIT CLICK", params);
    let rowData = params.row;
    // let response;

    // response = await axiosPrivate.get(`/loans/${rowData.TRANSACTIONID}`);
    // console.log("got loanDetail", response.data);
    // let loanDetail = response.data[0];

    // response = await axiosPrivate.get(`/clients/${rowData.CIFKEY}`);
    // let clientData = response.data[0];

    // console.log("Passing loanDetail", loanDetail);
    navigate("/addLoan", { state: { ...rowData } });
  };

  const handleOnRowClick = async (rowData) => {
    console.log("Clicked ->", rowData);

    if (rowData) {
      setSelectedId(rowData.TRANSACTIONID);
      setSelectedRow(rowData);
    }
  };

  function addLoan() {
    navigate("/addLoan");
  }

  async function doRefetch() {
    console.log("Doing refetch...");
    setOpenLoading(true);
    setTimeout(() => {
      setOpenLoading(false);
    }, 10000);
    const response = await axiosPrivate.get("/loans");
    console.log("All loans returned:", response);
    setAllLoans(response.data);
    if (selectedId) {
      let obj = response.data.find((o) => o.TRANSACTIONID === selectedId);
      if (obj) {
        setSelectedRow(obj);
      }
    } else {
      console.log("Refetch no selected row");
    }

    setOpenLoading(false);
    console.log("selected ID:", selectedId);
  }

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <Dialog open={openLoading}>
        <DialogContent>
          <QueryLoading />
        </DialogContent>
      </Dialog>

      <div
        className="d-flex flex-row justify-content-between"
        style={{ width: "95%" }}
      >
        <h2>
          All Loans
          <span>
            <Button onClick={doRefetch}>
              <FiRefreshCcw />
            </Button>
          </span>
        </h2>
        <Button
          size="small"
          className="mx-5 my-2"
          variant="contained"
          onClick={addLoan}
        >
          Add Loan
        </Button>
      </div>

      <Box mb={2} sx={{ height: selectedId ? 350 : 700, width: "95%" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "LoanDate", sort: "desc" }],
            },
          }}
          onRowClick={(param) => handleOnRowClick(param.row)}
          rowHeight={25}
          rows={allLoans}
          getRowId={(row) => row.TRANSACTIONID}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 700 },
            },
          }}
        />
      </Box>
      <FadeIn isVisible={selectedId}>
        <LoanDetail loanData={selectedRow} refetch={doRefetch} />
      </FadeIn>
    </Stack>
  );
}
