import React from "react";
import ReactDOM from "react-dom/client";
//import './index.css';
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-X7FNK387FD");
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
