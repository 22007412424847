import { Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { v4 as uuid } from "uuid";
import { confirmDialog } from "./ConfirmDialog";
import { axiosPrivate } from "../api/axios";
import EnterAdjustment from "./EnterAdjustment";

function AdjustmentList({ data, loanData, refetchAdjustment }) {
  const deleteAdjustment = async (adjustment) => {
    const response = await axiosPrivate.post("/adjustments/deleteAdjustment", {
      TRANSACTIONID: adjustment.TRANSACTIONID,
      Adjustment: adjustment.Adjustment,
      AdjDate: adjustment.AdjDate,
      Remarks: adjustment.Remarks,
    });
    console.log(
      `Delete Adjustment ${adjustment.Adjustment} returned: ${response}`
    );
    refetchAdjustment();
  };

  const columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "Transaction ID",
      flex: 3,
      editable: true,
    },
    {
      field: "AdjDate",
      headerName: "Adjustment Date",
      flex: 4,
      editable: true,
    },
    {
      field: "Adjustment",
      headerName: "Ajustment Amount",
      flex: 3,
      editable: true,
    },
    { field: "Remarks", headerName: "Remarks", flex: 8, editable: true },
    {
      field: "action",
      flex: 5,
      headerName: "Action",
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete adjustment", params);
          confirmDialog(
            `Do you really want to delete Adjustment ${params.row.Adjustment}`,
            () => deleteAdjustment(params.row)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <div className="">
      <DataGrid
        style={{ height: "300px" }}
        rowHeight={25}
        rows={data}
        getRowId={(row) => uuid()}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 700 },
          },
        }}
      />
      <div className="d-flex flex-row justify-content-center my-2">
        <EnterAdjustment
          loanData={loanData}
          refetchAdjustment={refetchAdjustment}
          sx={{ margin: 10 }}
        />
      </div>
    </div>
  );
}

export default AdjustmentList;
