import React from "react";
// material ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
} from "@mui/material";

import { Close } from "@mui/icons-material";
// zustand
import create from "zustand";
import { Alert } from "@mui/lab";

const useConfirmDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
  messageOnly: false,
}));

export const confirmDialog = (
  message: string,
  onSubmit: () => void,
  messageOnly: boolean
) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit,
    messageOnly,
  });
};

const ConfirmDialog = () => {
  const { message, onSubmit, close, messageOnly } = useConfirmDialogStore();
  return (
    <Dialog
      open={Boolean(onSubmit)}
      onClose={close}
      maxWidth="sm"
      fullWidth
      disablePortal
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          {messageOnly ? "Warning / Error" : "Confirm the action"}
        </DialogTitle>
        <IconButton onClick={close} size="large">
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Alert severity="error">{message}</Alert>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={close}>
          Close
        </Button>
        <Button
          hidden={messageOnly}
          color="secondary"
          variant="contained"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
