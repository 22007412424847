import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";

import {
  Paper,
  Popover,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Card,
  Autocomplete,
  TextField,
} from "@mui/material";
import { FiRefreshCcw } from "react-icons/fi";
import { Tab } from "bootstrap";
import LoanDetail from "../components/LoanDetail";
import { QueryError, QueryLoading } from "./QueryStatus";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { FadeIn } from "../components/Animations";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { MdExpandMore } from "react-icons/md";
import { useLocation } from "react-router-dom";

export default function Default() {
  const axiosPrivate = useAxiosPrivate();
  const { state } = useLocation();

  const [defaults, setDefaults] = useState([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [totalRepayable, setTotalRepayable] = useState(0);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalDefault, setTotalDefault] = useState(0);
  const [totalPenalties, setTotalPenalties] = React.useState(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [allDepartments, setAllDepartments] = useState([]);
  const [department, setDepartment] = useState("All Departments");

  async function fetchDefaultData(DeptCom = null) {
    setOpenLoading(true);
    // setTimeout(() => {
    //   setOpenLoading(false);
    // }, 40000);
    const response = await axiosPrivate.post(`/outstandingDefaults`, {
      DeptCom: DeptCom
        ? DeptCom
        : department === "All Department"
        ? null
        : department.split("-")[1],
    });
    setDefaults(response.data);
    calculateTotal(response.data);
    if (selectedId) {
      let obj = response.data.find((o) => o.TRANSACTIONID === selectedId);
      if (obj) {
        setSelectedRow(obj);
      }
    } else {
      console.log("Refetch no selected row");
    }
    setOpenLoading(false);
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     setOpenLoading(true);
  //     const response = await axiosPrivate.post(`/outstandingDefaults`, {
  //       DeptCom:
  //         department === "All Department" ? null : department.split("-")[1],
  //     });
  //     setDefaults(response.data);
  //     calculateTotal(response.data);
  //     setOpenLoading(false);
  //   }
  //   fetchData();
  // }, [department]);

  useEffect(() => {
    async function fetchData() {
      let response;
      response = await axiosPrivate.get("/departments");
      console.log("Departments:", response.data);
      setAllDepartments(response.data);

      if (state) {
        console.log("Parsed in state", state);
        setDepartment(`${state.DeptName}-${state.DeptCom}`);
        fetchDefaultData(state.DeptCom);
      }
    }
    fetchData();
  }, []);

  const calculateTotal = (data) => {
    if (!data) return undefined;

    console.log("Calculate CLICKED");
    setOpenPopover(true);
    let totalrpab = 0;
    let totalrpmt = 0;
    let totalInt = 0;
    let totalDF = 0;
    let totalDebt = 0;

    data.forEach((item) => {
      totalrpab += parseInt(item.Repayable);
      totalrpmt += parseInt(item.TotalRepayment);
      totalInt += parseInt(item.Interest);
      totalDF += parseInt(item.Default);
      totalDebt += parseInt(item.OutstandingDebt);
    });
    setTotalRepayable(totalrpab);
    setTotalRepayment(totalrpmt);
    setTotalInterest(totalInt);
    setTotalPenalties(totalDF);
    setTotalDefault(totalDebt);
    console.log(
      "totalrpab totalrpmt totalInt totalDF setTotalDefault",
      totalrpab,
      totalrpmt,
      totalInt,
      totalDF,
      totalDebt
    );
  };

  const numberComparator = (v1, v2) => parseInt(v1) - parseFloat(v2);

  const columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "TRANSACTIONID",
      flex: 5,
    },
    {
      field: "ClientName",
      headerName: "Client Name",
      flex: 9,
    },
    { field: "CIFKEY", headerName: "CIFKEY", flex: 6 },
    { field: "DeptName", headerName: "Dept Name", flex: 7 },
    { field: "LoanDate", headerName: "Loan Date", flex: 4 },
    {
      field: "Repayable",
      headerName: "Repayable",
      flex: 6,
      sortComparator: numberComparator,
    },
    { field: "NOF", headerName: "NOF", flex: 4 },
    {
      field: "Deduction",
      headerName: "Deduction",
      flex: 6,
      sortComparator: numberComparator,
    },
    {
      field: "TotalRepayment",
      headerName: "Repayment",
      flex: 6,
      sortComparator: numberComparator,
    },
    {
      field: "Default",
      headerName: "Penalty",
      flex: 6,
      sortComparator: numberComparator,
    },
    {
      field: "TotalAdjustment",
      headerName: "Adjustment",
      flex: 6,
      sortComparator: numberComparator,
    },
    {
      field: "OutstandingDebt",
      headerName: "Outstanding Debt",
      flex: 7,
      sortComparator: numberComparator,
    },
    { field: "AcountStatus", headerName: "Status", flex: 6 },
  ];

  const handleOnRowClick = async (param) => {
    console.log("Default list selected row:", param);
    if (param.row) {
      setSelectedId(param.id);
      setSelectedRow(param.row);
    }
  };

  async function doRefetch() {
    console.log("Doing refetch...");
    fetchDefaultData();
    console.log("selected ID:", selectedId);
  }

  var triggerTabList = [].slice.call(document.querySelectorAll("#myTab a"));
  triggerTabList.forEach(function (triggerEl) {
    var tabTrigger = new Tab(triggerEl);

    triggerEl.addEventListener("click", function (event) {
      event.preventDefault();
      tabTrigger.show();
    });
  });

  const allDepartmentProps = {
    options: ["All Departments"].concat(
      allDepartments.map((option) => `${option.DeptName}-${option.DeptCom}`)
    ),
  };
  const onDepartmentChange = (event, values) => {
    //console.log("Auto", event, values);
    setDepartment(values);
  };

  const handleSearch = async () => {
    setDefaults([]);
    setSelectedId(null);
    setSelectedRow([]);
    fetchDefaultData();
  };

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <Dialog open={openLoading}>
        <DialogContent>
          <QueryLoading />
        </DialogContent>
      </Dialog>

      <Card sx={{ padding: 1, marginTop: 2, width: "95%" }}>
        <div className="d-flex flex-row pb-2 justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center">
            <h6 style={{ color: "#e24f05", fontSize: "14px" }}>
              Search Defaulters per Department:{" "}
            </h6>
            <Autocomplete
              {...allDepartmentProps}
              disableClearable
              value={department}
              id="Department"
              name="Department"
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option === value
              }
              sx={{ minWidth: 400, marginLeft: 2 }}
              onChange={onDepartmentChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={department}
                  label="Department"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
          <Button
            style={{
              borderRadius: 10,
              width: 150,
            }}
            variant="contained"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Card>

      <div
        className="d-flex flex-row align-items-center"
        style={{ width: "95%" }}
      >
        <h2 style={{ marginRight: 15 }}>
          Default Loans
          {/* <span>
            <Button onClick={doRefetch}>
              <FiRefreshCcw />
            </Button>
          </span> */}
        </h2>

        {/* <Button
          className="mx-5"
          hidden={defaults.length === 0}
          variant="contained"
          onClick={calculateTotal}
        >
          Calculate Total
        </Button> */}

        <Typography>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Total Repayable</th>
                <td>{parseInt(totalRepayable).toFixed(2)}</td>
                <th scope="row">Total Repayment</th>
                <td>{parseInt(totalRepayment).toFixed(2)}</td>
              </tr>
              <tr>
                <th scope="row">Total Penalty</th>
                <td>{parseInt(totalPenalties).toFixed(2)}</td>
                <th scope="row">Total Outstanding Debt</th>
                <td className=" text-bg-danger">
                  <strong>{parseInt(totalDefault).toFixed(2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Typography>
      </div>

      <Box sx={{ height: selectedId ? 300 : 500, width: "95%" }}>
        <DataGrid
          rowHeight={25}
          rows={defaults}
          getRowId={(row) => row.TRANSACTIONID}
          onRowClick={(param) => handleOnRowClick(param)}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <FadeIn isVisible={selectedId}>
        <LoanDetail loanData={selectedRow} refetch={doRefetch} />
      </FadeIn>
    </Stack>
  );
}
