import { Autocomplete, Box, Button, Card, TextField } from "@mui/material";

import { useEffect, useState, useRef } from "react";
import { getPnoArray } from "../utils/helper";
import bootstrap from "bootstrap/dist/js/bootstrap.min";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Option, Select } from "@mui/joy";
import { confirmDialog } from "../components/ConfirmDialog";
import LoanSummary from "./LoanSummary";

function EnterDefault({ loanData, refetchDefault }) {
  const axiosPrivate = useAxiosPrivate();
  const modalRef = useRef();
  const [pno, setPno] = useState(1);
  const [pnoYear, setPnoYear] = useState(new Date().getFullYear());

  const initialFormState = {
    TRANSACTIONID: loanData.TRANSACTIONID,
    Mode: "",
    Reference: "",
    Default: "",
    Code: "DEF",
    PayNo:
      pno.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      pnoYear.toString().slice(-2),
    PayDate: null,
    Remarks: "",
    CIFKEY: loanData.CIFKEY,
  };
  console.log("Enterpayment loanData", loanData);
  const [defaultData, setDefaultData] = useState(initialFormState);

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setDefaultData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setDefaultData((prevData) => ({
      ...prevData,
      PayNo:
        pno.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        "-" +
        pnoYear.toString().slice(-2),
    }));
    console.log("setting pno", pno, pnoYear);
  }, [pno, pnoYear]);

  const inputField = (label, name, required = false, readOnly = false) => {
    return (
      <TextField
        id={name}
        name={name}
        label={label}
        variant="outlined"
        size="small"
        className="col m-2"
        value={defaultData[name]}
        onChange={handleChange}
        required={required}
        inputProps={{ readOnly: readOnly }}
      />
    );
  };

  useEffect(() => {
    setDefaultData(initialFormState);
  }, [loanData]);

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new bootstrap.Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrap.Modal.getInstance(modalEle);
    setDefaultData(initialFormState);
    bsModal.hide();
  };

  const submitDefault = async (e) => {
    e.preventDefault();

    let submitData = {
      CIFKEY: loanData.CIFKEY,
      TRANSACTIONID: loanData.TRANSACTIONID,
      Reference: defaultData.Reference,
      Default: parseFloat(defaultData.Default),
      Code: defaultData.Code,
      Remarks: defaultData.Remarks,
    };
    console.log("submitting default...", submitData);

    const response = await axiosPrivate
      .post("/defaults/addDefault", submitData)
      .catch((error) => {
        console.log("Add default error", error);
        confirmDialog(
          `Unable to enter Default for : ${defaultData.Default}.`,
          () => {},
          true
        );
      });
    console.log("Add Default Returned:", response);
    if (response) {
      refetchDefault();
      hideModal();
    }
  };

  return (
    <div>
      <Button
        style={{ width: "275px" }}
        variant="contained"
        size="small"
        onClick={showModal}
      >
        Enter Default
      </Button>

      <div className="modal fade" ref={modalRef} tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Enter Default
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <LoanSummary loanData={loanData} />
                <Card sx={{ padding: 1, marginBottom: 2 }}>
                  <Box container m={2}>
                    <div className="row d-flex align-items-center">
                      {inputField("Default Amount", "Default", true)}
                      {inputField("Reference", "Reference")}
                    </div>
                    <div className="row align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <span>PNO： </span>
                        <Select
                          required
                          defaultValue={1}
                          onChange={(e, value) => setPno(value)}
                          sx={{
                            size: "lg",
                            width: 100,
                            fontSize: 16,
                            marginLeft: 1,
                            marginRight: 1,
                          }}
                        >
                          {[...Array(27)].map((_, i) => (
                            <Option sx={{ fontSize: 16 }} key={i} value={i + 1}>
                              {i + 1}
                            </Option>
                          ))}
                        </Select>
                        <h6>Year:</h6>
                        <Select
                          defaultValue={new Date().getFullYear()}
                          onChange={(e, value) => {
                            setPnoYear(value);
                          }}
                          sx={{
                            size: "sm",
                            width: 130,
                            fontSize: 16,
                            marginLeft: 2,
                            marginRight: 4,
                          }}
                        >
                          {[...Array(10)].map((_, i) => (
                            <Option
                              sx={{ fontSize: 16 }}
                              key={i}
                              value={new Date().getFullYear() - i}
                            >
                              {new Date().getFullYear() - i}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      {inputField("Remarks", "Remarks", true)}
                    </div>
                  </Box>
                </Card>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitDefault}
                disabled={!defaultData.Default || !defaultData.Remarks}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterDefault;
