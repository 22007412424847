import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route, Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import ClientList from "./pages/ClientList";
import AddClient from "./pages/AddClient";
import LoanList from "./pages/LoanList";
import Default from "./pages/Default";
import Repayment from "./pages/Repayment";
import Bank from "./pages/Bank";
import Dashboard from "./pages/Dashboard";
import AddLoan from "./pages/AddLoan";
import ChangePassword from "./pages/ChangePassword";
import ConfirmDialog from "./components/ConfirmDialog";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const ROLES = {
  User: 1,
  Editor: 2,
  Admin: 4,
};

const SidebarLayout = () => (
  <div className="d-flex flex-row">
    <Sidebar />
    <Outlet />
  </div>
);

function App() {
  console.log("Process env", process.env.NODE_ENV);

  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <ConfirmDialog />
      <Routes>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<SidebarLayout />}>
            <Route element={<RequireAuth allowedRoles={ROLES.User} />}>
              <Route path="/" element={<Dashboard />} />
            </Route>
            <Route path="dashboard" element={<Dashboard />}></Route>

            <Route path="clientList" element={<ClientList />}></Route>
            <Route path="addClient" element={<AddClient />}></Route>
            <Route path="loanList" element={<LoanList />}></Route>
            <Route path="addLoan" element={<AddLoan />}></Route>
            <Route path="default" element={<Default />}></Route>
            <Route path="repayment" element={<Repayment />}></Route>

            <Route path="changePassword" element={<ChangePassword />}></Route>
            <Route element={<RequireAuth allowedRoles={ROLES.User} />}>
              <Route path="bank" element={<Bank />}></Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
              <Route path="register" element={<Register />} />
            </Route>
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Routes>
    </>
  );
}

export default App;
