import { Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { v4 as uuid } from "uuid";
import { confirmDialog } from "./ConfirmDialog";
import { axiosPrivate } from "../api/axios";
import EnterRepayment from "./EnterRepayment";

function RepaymentList({ data, loanData, refetchRepayment }) {
  const deleteRepayment = async (repayment) => {
    const response = await axiosPrivate.post("/repayments/deleteRepayment", {
      TRANSACTIONID: repayment.TRANSACTIONID,
      PayNo: repayment.PayNo,
      Amount: repayment.Amount,
    });
    console.log(`Delete repayment ${repayment.PayNo} returned: ${response}`);
    refetchRepayment();
  };

  const columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "Transaction ID",
      flex: 6,
      editable: true,
    },
    {
      field: "RepaymentDate",
      headerName: "Repayment Date",
      flex: 8,
      editable: true,
    },
    { field: "PayNo", headerName: "Pay No", flex: 5, editable: true },
    { field: "Amount", headerName: "Amount", flex: 7, editable: true },
    { field: "Fee", headerName: "Fee", flex: 7, editable: true },
    { field: "Net", headerName: "Net", flex: 7, editable: true },
    { field: "Remarks", headerName: "Remarks", flex: 8, editable: true },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete bank", params);
          confirmDialog(
            `Do you really want to delete repayment ${params.row.Amount} for ${params.row.PayNo}?`,
            () => deleteRepayment(params.row)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <div className="">
      <DataGrid
        style={{ height: "300px" }}
        rowHeight={25}
        rows={data}
        getRowId={(row) => uuid()}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 700 },
          },
        }}
      />
      <div className="d-flex flex-row justify-content-center my-2">
        <EnterRepayment
          loanData={loanData}
          refetchRepayment={refetchRepayment}
          sx={{ margin: 10 }}
        />
      </div>
    </div>
  );
}

export default RepaymentList;
