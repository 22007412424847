import React from "react";
import mainLogo from "./../assets/logo512.png";

export const CeaseForm = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ width: "750px" }} className=" mx-4 my-5">
      <div className="d-flex flex-row justify-content-center gap-4 m-3">
        <img
          src={mainLogo}
          style={{ width: "100px", height: "100px" }}
          alt="1AVini"
        />
        <div className="d-flex flex-column justify-content-center text-center">
          <h2 style={{ marginBottom: "-7px" }}>
            1A VINI Finance Company Limited
          </h2>
          <small>www.1avini.com</small>
          <div className=" w-100 border-top"></div>
          <div className="d-flex flex-column">
            <span>P.O. Box 1378, Boroko, N.C.D.</span>
            <div className="d-flex justify-content-center gap-5">
              <span>Tel: 325-4646 </span>
              <span>Email: sales@1avini.com</span>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-100 border-top text center"></div>
      <h2 className=" text-center mt-2">Cease Form Authorization</h2>

      <div className="m-5">
        <span>The Pay Master</span>
        <br />
        <span>{props.loan.DeptName}</span>

        <p className="mt-5">
          Please be advised to cease the deduction for the client stated below.
          The loan obtained has been fully repaid.
        </p>

        <div className="d-flex flex-row justify-content-center mt-3 gap-5 w-auto">
          <div className="d-flex flex-column">
            <div>Client: {props.loan.ClientName}</div>
            <div>File No: {props.loan.CIFKEY}</div>
          </div>
          <div className="d-flex flex-column">
            <div>TRA ID: {props.loan.TRANSACTIONID}</div>
            <div>
              Loan Date: {new Date(props.loan.LoanDate).toJSON().slice(0, 10)}
            </div>
            <div>Loan Amount: K{props.loan.LoanAmount.toFixed(2)}</div>
            <div>Repayable Amount: K{props.loan.Repayable.toFixed(2)}</div>
            <div>Deduction Amount: K{props.loan.Deduction.toFixed(2)}</div>
          </div>
        </div>

        <div style={{ marginTop: "100px" }}>
          <p>Thank you for your attention!</p>
        </div>
        <div>
          <p>{new Date().toJSON().slice(0, 10)}</p>
        </div>
      </div>
    </div>
  );
});
