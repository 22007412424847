import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";

const AnalyticCard = ({
  color,
  title,
  count,
  percentage,
  currency,
  extra,
  sinceText = "since last week",
}) => {
  const [frontText, setFrontText] = useState("You have gained extra");
  const [isLoss, setIsLoss] = useState(false);

  useEffect(() => {
    if (extra < 0) {
      setFrontText("You have made less ");
      setIsLoss(true);
    }
  }, [extra]);

  return (
    <Card
      style={{
        background: color,
      }}
    >
      <CardContent
        style={{
          paddingBottom: "4px",
          paddingTop: "10px",
          minWidth: "250px",
        }}
      >
        <span style={{ color: "#222222", fontSize: "14px" }}>{title}</span>
        <h3 className="my-3">
          {currency}
          {count}
        </h3>
        <span style={{ fontSize: 11, color: "0xff5555" }}>
          {percentage}% {sinceText}
        </span>
      </CardContent>
    </Card>
  );
};

export default AnalyticCard;
