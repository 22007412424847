import { Card } from "@mui/material";
import React from "react";

function LoanSummary({ loanData }) {
  const loanInfoItem = (label, data) => {
    return (
      <tr>
        <th className=" ps-2 text-end">{label}</th>
        <td>{data}</td>
      </tr>
    );
  };

  return (
    <>
      <table className="table table-bordered table-striped table-sm shadow-sm">
        <tbody>
          {loanInfoItem("Client Name", loanData.ClientName)}
          {loanInfoItem("CIFKEY", loanData.CIFKEY)}
          {loanInfoItem("Repayable Amount", loanData.Repayable)}
          {loanInfoItem("Outstanding Debt", loanData.OutstandingDebt)}
          {loanInfoItem("Loan Date", loanData.LoanDate)}
        </tbody>
      </table>
    </>
  );
}

export default LoanSummary;
