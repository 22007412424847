import React from "react";
import mainLogo from "./../assets/logo512.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export const LoanStatement = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ width: "750px" }} className=" mx-4 my-5">
      <div className="d-flex flex-row justify-content-center gap-4 m-3">
        <img
          src={mainLogo}
          style={{ width: "100px", height: "100px" }}
          alt="1AVini"
        />
        <div className="d-flex flex-column justify-content-center text-center">
          <h2 style={{ marginBottom: "-7px" }}>
            1A VINI Finance Company Limited
          </h2>
          <small>www.1avini.com</small>
          <div className=" w-100 border-top"></div>
          <div className="d-flex flex-column">
            <span>P.O. Box 1378, Boroko, N.C.D.</span>
            <div className="d-flex justify-content-center gap-5">
              <span>Tel: 325-4646 </span>
              <span>Email: sales@1avini.com</span>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-100 border-top "></div>
      <h2 className=" text-center mt-2">Loan Statement</h2>
      <div className="d-flex flex-row justify-content-between mt-3 gap-5 w-auto">
        <div className="d-flex flex-column">
          <div>Client: {props.loan.ClientName}</div>
          <div>File No: {props.loan.CIFKEY}</div>
          <div>Department: {props.loan.DeptName}</div>
        </div>
        <div className="d-flex flex-column">
          <div>TRA ID: {props.loan.TRANSACTIONID}</div>
          <div>
            Loan Date: {new Date(props.loan.LoanDate).toJSON().slice(0, 10)}
          </div>
          <div>Loan Amount: K{props.loan.LoanAmount.toFixed(2)}</div>
          <div>Repayable Amount: K{props.loan.Repayable.toFixed(2)}</div>
          <div>Deduction Amount: K{props.loan.Deduction.toFixed(2)}</div>
          <div>Total Penalty: K{props.totalPenalty.toFixed(2)}</div>
          <div>Total Repayment: K{props.totalRepayment.toFixed(2)}</div>
          <div className=" border-top">
            <b>Balance: K{props.balance.toFixed(2)}</b>
          </div>
        </div>
      </div>
      <h3 className="mt-2">Repayments</h3>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Pay No.</TableCell>
                <TableCell align="right">Amount&nbsp;(K)</TableCell>
                <TableCell align="right">Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.repayment.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right">{row.PayNo}</TableCell>
                  <TableCell align="right">{row.Amount.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.Remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div style={{ marginTop: "100px" }}>
        <p>Thank you!</p>
      </div>
      <div>
        <p>{new Date().toJSON().slice(0, 10)}</p>
      </div>
    </div>
  );
});
