import React, { useState, useEffect, useRef } from "react";

import {
  Paper,
  Button,
  ToggleButton,
  Stack,
  ToggleButtonGroup,
  Divider,
  ButtonGroup,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import RepaymentList from "../components/RepaymentList";
import RefundList from "../components/RefundList";
import DefaultList from "../components/DefaultList";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdjustmentList from "./AdjustmentList";
import { confirmDialog } from "./ConfirmDialog";
import { NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useReactToPrint } from "react-to-print";
import { CeaseForm } from "./../components/CeaseForm";
import { LoanStatement } from "./../components/LoanStatement";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoanDetail({ loanData, refetch }) {
  const axiosPrivate = useAxiosPrivate();
  //const [loanData, setLoanData] = useState({});
  const [totalAdjustments, setTotalAdjustments] = React.useState(0);
  const [totalDefaults, setTotalDefaults] = React.useState(0);
  const [repayments, setRepayments] = React.useState([]);
  const [refunds, setRefunds] = React.useState([]);
  const [defaults, setDefaults] = React.useState([]);
  const [adjustments, setAdjustments] = React.useState([]);
  const [totalRepayments, setTotalRepayments] = React.useState(0);
  const [totalRefunds, setTotalRefunds] = React.useState(0);
  const [balance, setBalance] = React.useState(0);

  const [openPdf, setOpenPdf] = React.useState(false);
  const [form, setForm] = React.useState("");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClickCeaseForm = () => {
    setForm("ceaseForm");
    setOpenPdf(true);
  };

  const handleClickLoanStatement = () => {
    setForm("loanStatement");
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const [currentComponent, setCurrentComponent] = useState("balanceBtn");

  const handleCurrentComponentChange = (event, nextCurrentComponent) => {
    if (nextCurrentComponent !== null) {
      setCurrentComponent(nextCurrentComponent);
    }
  };

  async function fetchRepayment() {
    const response = await axiosPrivate.get(
      `/repayments/${loanData.TRANSACTIONID}`
    );
    console.log("Repayment Detail:", response.data);
    setRepayments(response.data);
    let totalRP = 0;
    response.data.forEach((item) => {
      totalRP += item.Amount;
    });
    setTotalRepayments(totalRP);
  }

  async function fetchRefund() {
    const response = await axiosPrivate.get(
      `/refunds/${loanData.TRANSACTIONID}`
    );
    console.log("Refunds Detail:", response.data);
    setRefunds(response.data);
    let totalRF = 0;
    response.data.forEach((item) => {
      totalRF += item.Refund;
    });
    setTotalRefunds(totalRF);
  }

  async function fetchDefault() {
    const response = await axiosPrivate.get(
      `/defaults/${loanData.TRANSACTIONID}`
    );
    console.log("Default Detail:", response.data);
    setDefaults(response.data);
    let totalDF = 0;
    response.data.forEach((item) => {
      totalDF += item.Default;
    });
    setTotalDefaults(totalDF);
  }

  async function fetchAdjustments() {
    const response = await axiosPrivate.get(
      `/adjustments/${loanData.TRANSACTIONID}`
    );
    console.log("Adjustment Detail:", response.data);
    setAdjustments(response.data);
    let totalAD = 0;
    response.data.forEach((item) => {
      totalAD += item.Adjustment;
    });
    console.log("Total adjustments", totalAD);
    setTotalAdjustments(totalAD);
  }

  useEffect(() => {
    console.log("useEffect loanData", loanData);
    if (!loanData || !loanData?.TRANSACTIONID) return undefined;

    async function u_fetchRepayment() {
      const response = await axiosPrivate.get(
        `/repayments/${loanData.TRANSACTIONID}`
      );
      console.log("Repayment Detail:", response.data);
      setRepayments(response.data);
      let totalRP = 0;
      response.data.forEach((item) => {
        totalRP += item.Amount;
      });
      setTotalRepayments(totalRP);
    }

    async function u_fetchRefund() {
      const response = await axiosPrivate.get(
        `/refunds/${loanData.TRANSACTIONID}`
      );
      console.log("Refunds Detail:", response.data);
      setRefunds(response.data);
      let totalRF = 0;
      response.data.forEach((item) => {
        totalRF += item.Refund;
      });
      setTotalRefunds(totalRF);
    }

    async function u_fetchDefault() {
      const response = await axiosPrivate.get(
        `/defaults/${loanData.TRANSACTIONID}`
      );
      console.log("Default Detail:", response.data);
      setDefaults(response.data);
      let totalDF = 0;
      response.data.forEach((item) => {
        totalDF += item.Default;
      });
      setTotalDefaults(totalDF);
    }

    async function u_fetchAdjustments() {
      const response = await axiosPrivate.get(
        `/adjustments/${loanData.TRANSACTIONID}`
      );
      console.log("Adjustment Detail:", response.data);
      setAdjustments(response.data);
      let totalAD = 0;
      response.data.forEach((item) => {
        totalAD += item.Adjustment;
      });
      console.log("Total adjustments---", totalAD);
      setTotalAdjustments(totalAD);
    }

    console.log("FETCHING DATA...................");
    u_fetchRepayment();
    u_fetchRefund();
    u_fetchDefault();
    u_fetchAdjustments();
  }, [axiosPrivate, loanData, loanData.TRANSACTIONID]);

  useEffect(() => {
    setBalance(
      loanData.Repayable -
        totalRepayments +
        totalRefunds +
        totalDefaults +
        totalAdjustments
    );
  }, [
    loanData.Repayable,
    totalAdjustments,
    totalDefaults,
    totalRefunds,
    totalRepayments,
  ]);

  async function toggleLoanStatus(active) {
    let status = active ? "Closed" : "Active";

    const submitData = {
      TRANSACTIONID: loanData.TRANSACTIONID,
      AcountStatus: status,
    };
    const response = await axiosPrivate.put(
      "/loans/updateLoanStatus",
      submitData
    );
    console.log(`Update Account Status - ${status} Returned:", ${response}`);

    if (refetch) {
      refetch();
    }
  }

  return (
    <>
      <Stack spacing={0} sx={{ width: "100%" }} alignItems="left">
        <ToggleButtonGroup
          size="small"
          fullWidth
          color="primary"
          value={currentComponent}
          onChange={handleCurrentComponentChange}
          exclusive
        >
          <ToggleButton size="small" value={"balanceBtn"}>
            Loan Details
          </ToggleButton>
          <ToggleButton size="small" value={"repaymentBtn"}>
            Repayments
          </ToggleButton>
          <ToggleButton size="small" value={"refundBtn"}>
            Refunds
          </ToggleButton>
          <ToggleButton size="small" value={"defaultBtn"}>
            Default
          </ToggleButton>
          <ToggleButton size="small" value={"AdjustmentBtn"}>
            Adjustment
          </ToggleButton>
        </ToggleButtonGroup>

        {currentComponent === "repaymentBtn" && (
          <RepaymentList
            data={repayments}
            loanData={loanData}
            refetchRepayment={fetchRepayment}
          />
        )}

        {currentComponent === "refundBtn" && (
          <RefundList
            data={refunds}
            loanData={loanData}
            refetchRefund={fetchRefund}
          />
        )}
        {currentComponent === "defaultBtn" && (
          <DefaultList
            data={defaults}
            loanData={loanData}
            refetchDefault={fetchDefault}
          />
        )}
        {currentComponent === "AdjustmentBtn" && (
          <AdjustmentList
            data={adjustments}
            loanData={loanData}
            refetchAdjustment={fetchAdjustments}
          />
        )}
        {currentComponent === "balanceBtn" && (
          <div className="p-2">
            <div className="d-flex row-cols-2 flex-row justify-content-evenly gap-2 h-75">
              <table className="table table-bordered table-striped w-50">
                <tbody>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Loan Date</th>
                    <td>{loanData.LoanDate}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Loan Status</th>
                    <td className="d-flex flex-row gap-3 align-items-center">
                      <span>{loanData.AcountStatus}</span>
                      <Button
                        style={{ width: "75px" }}
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => {
                          confirmDialog(
                            `Do you really want to ${
                              loanData.AcountStatus !== "Active"
                                ? "ACTIVATE"
                                : "CLOSE"
                            } loan ID: ${loanData.TRANSACTIONID}?`,
                            () =>
                              toggleLoanStatus(
                                loanData.AcountStatus === "Active"
                              )
                          );
                        }}
                      >
                        {loanData.AcountStatus === "Active"
                          ? "CLose"
                          : "Activate"}
                      </Button>
                    </td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Transaction ID</th>
                    <td>{loanData.TRANSACTIONID}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Client Name</th>
                    <td>{loanData.ClientName}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Employment ID</th>
                    <td>{loanData.CIFKEY}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Department (Code)</th>
                    <td>
                      {loanData.DeptName} ({loanData.DeptCom})
                    </td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Loan Amount</th>
                    <td>K{loanData.LoanAmount}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Interest</th>
                    <td>K{loanData.Interest}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">Deduction</th>
                    <td>K{loanData.Deduction}</td>
                  </tr>
                  <tr style={{ height: 20 }}>
                    <th scope="row">No. of Fortnights</th>
                    <td>{loanData.NOF}</td>
                  </tr>
                </tbody>
              </table>

              <div className="w-50">
                <table className="table table-bordered table-striped">
                  <tbody>
                    <tr style={{ height: 20 }}>
                      <th scope="row">Total Repayable</th>
                      <td>K{parseInt(loanData.Repayable)}</td>
                    </tr>
                    <tr style={{ height: 20 }}>
                      <th scope="row">Total Repayments</th>
                      <td>K{totalRepayments?.toFixed(2)}</td>
                    </tr>
                    <tr style={{ height: 20 }}>
                      <th scope="row">Total Refunds</th>
                      <td>K{totalRefunds?.toFixed(2)}</td>
                    </tr>
                    <tr style={{ height: 20 }}>
                      <th scope="row">Total Defaults</th>
                      <td>K{totalDefaults?.toFixed(2)}</td>
                    </tr>
                    <tr style={{ height: 20 }}>
                      <th scope="row">Total Adjustments</th>
                      <td>{totalAdjustments}</td>
                    </tr>
                    <tr style={{ height: 20 }}>
                      <th scope="row">Total Owe</th>
                      <td>K{balance?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex gap-2 d-flex-row">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={handleClickCeaseForm}
                  >
                    Cease Form
                  </Button>

                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={handleClickLoanStatement}
                  >
                    Loan Statement
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Stack>

      <Dialog
        fullScreen
        open={openPdf}
        onClose={handleClosePdf}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClosePdf}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <Button autoFocus color="inherit" onClick={handleClosePdf}>
                Close
              </Button>
            </Typography>
            <Button autoFocus color="inherit" onClick={handlePrint}>
              Print
            </Button>
          </Toolbar>
        </AppBar>
        <div>
          {form === "ceaseForm" ? (
            <CeaseForm ref={componentRef} loan={loanData} />
          ) : (
            <LoanStatement
              ref={componentRef}
              loan={loanData}
              repayment={repayments}
              totalRepayment={totalRepayments}
              totalPenalty={totalDefaults}
              balance={balance}
            />
          )}
        </div>
      </Dialog>
    </>
  );
}

export default LoanDetail;
