import React, { useState } from "react";
import useLogout from "../hooks/useLogout";

import {
  FaTh,
  FaBars,
  FaBomb,
  FaMoneyBillWave,
  FaMoneyCheckAlt,
  FaRegMoneyBillAlt,
} from "react-icons/fa";

import { BsBank, BsPersonAdd } from "react-icons/bs";
import { HiOutlineUsers } from "react-icons/hi";
import { GiTakeMyMoney, GiMoneyStack } from "react-icons/gi";
import {
  RiLogoutBoxRLine,
  RiLockPasswordLine,
  RiMoneyDollarBoxLine,
} from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import mainLogo from "./../assets/logo512.png";
import useAuth from "./../hooks/useAuth";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FiAlertOctagon } from "react-icons/fi";

export default function Sidebar({ children }) {
  const { auth } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const logout = useLogout();

  const isAdmin = () => {
    console.log("is admin roles", auth.roles);
    return auth.roles & 4 ? true : false;
  };

  const menuItem = [
    {
      path: "dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "clientList",
      name: "Clients",
      icon: <HiOutlineUsers />,
    },
    {
      path: "loanList",
      name: "Loans",
      icon: <BiMoneyWithdraw />,
    },

    {
      path: "divider",
      name: "Reports",
    },
    {
      path: "default",
      name: "Defaults",
      icon: <FiAlertOctagon />,
    },
    {
      path: "repayment",
      name: "Repayments",
      icon: <FaRegMoneyBillAlt />,
    },
    {
      path: "divider",
      name: "Maintenance",
    },
    {
      path: "bank",
      name: "Banks",
      icon: <BsBank />,
    },
    {
      path: "changePassword",
      name: "Change Password",
      icon: <RiLockPasswordLine />,
    },
    {
      role: "Admin",
      path: "register",
      name: "Register User",
      icon: <BsPersonAdd />,
    },
  ];

  const singOut = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <>
      <div
        style={{
          minWidth: isOpen ? "200px" : "50px",
          width: isOpen ? "200px" : "50px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <img src={mainLogo} style={{ width: "20px" }} alt="1AVini" />
          <span style={{ display: isOpen ? "block" : "none" }} className="logo">
            1AVINI
          </span>
          <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => {
          if (item.path === "divider") {
            return (
              <div
                key={index + 1000}
                className="mt-3"
                style={{ display: isOpen ? "block" : "none" }}
              >
                <Divider className="bg-light"></Divider>
                <span className="px-2 mt-3 justify-content-center text-warning">
                  {item.name}
                </span>
              </div>
            );
          } else if (!item.role || (item.role === "Admin" && isAdmin())) {
            return (
              <NavLink to={item.path} key={index} className="link">
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            );
          }
        })}
        <NavLink to={"/login"} className="link" onClick={singOut}>
          <div className="icon">
            <RiLogoutBoxRLine />
          </div>
          <div
            style={{ display: isOpen ? "block" : "none" }}
            className="link_text"
          >
            Logout
          </div>
        </NavLink>
      </div>
      <main>{children}</main>
    </>
  );
}
