import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://localhost:2698"
    : "https://nakalo.1avini.com:2698";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
