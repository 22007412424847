import { Autocomplete, Box, Button, Card, TextField } from "@mui/material";

import { useEffect, useState, useRef } from "react";
import { getPnoArray } from "../utils/helper";
import bootstrap from "bootstrap/dist/js/bootstrap.min";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Option, Select } from "@mui/joy";
import LoanSummary from "./LoanSummary";

function EnterRefund({ loanData, refetchRefund }) {
  const axiosPrivate = useAxiosPrivate();
  const modalRef = useRef();
  const [modes, setModes] = useState([]);

  const initialFormState = {
    TRANSACTIONID: loanData.TRANSACTIONID,
    CIFKEY: loanData.CIFKEY,
    Mode: "",
    Reference: "",
    Refund: 0,
    Fees: 0,
    NetRefund: 0,
    Remarks: "",
    Code: "",
  };
  const [refundData, setRefundData] = useState(initialFormState);
  console.log("EnterRefund loanData", loanData);

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setRefundData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      let response;
      response = await axiosPrivate.get("/modes");
      console.log("All modes result:", response);
      setModes(response.data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setRefundData((prevData) => ({
      ...prevData,
      NetRefund: refundData.Refund - refundData.Fees,
    }));
  }, [refundData.Refund, refundData.Fees]);

  const inputField = (label, name, required = false, readOnly = false) => {
    return (
      <TextField
        id={name}
        name={name}
        label={label}
        variant="outlined"
        size="small"
        className="col m-2"
        value={refundData[name]}
        onChange={handleChange}
        required={required}
        inputProps={{ readOnly: readOnly }}
      />
    );
  };

  useEffect(() => {
    setRefundData(initialFormState);
  }, [loanData]);

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new bootstrap.Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrap.Modal.getInstance(modalEle);
    setRefundData(initialFormState);
    bsModal.hide();
  };

  const submitRefund = async (e) => {
    e.preventDefault();

    let submitData = {
      CIFKEY: loanData.CIFKEY,
      TRANSACTIONID: loanData.TRANSACTIONID,

      Mode: refundData.Mode,
      Reference: refundData.Reference,
      Refund: refundData.Refund,
      Fees: refundData.Fees,
      NetRefund: refundData.NetRefund,
      Remarks: refundData.Remarks,
      Code: "REF",
    };
    console.log("submitting refund...", submitData);

    const response = await axiosPrivate.post("/refunds/addRefund", submitData);
    console.log("Add Refund Returned:", response);
    if (response) {
      refetchRefund();
      hideModal();
    }
  };

  const modeProps = {
    options: modes.map((option) => option.Mode),
  };

  return (
    <div>
      <Button
        style={{ width: "275px" }}
        variant="contained"
        size="small"
        onClick={showModal}
      >
        Enter Refund
      </Button>

      <div className="modal fade" ref={modalRef} tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Enter Refund
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <LoanSummary loanData={loanData} />

                <Card sx={{ padding: 1, marginBottom: 2 }}>
                  <Box container m={2}>
                    <div className=" d-flex flex-row align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <span>Mode </span>
                        <Select
                          required
                          onChange={(e, value) => {
                            console.log("Mode value:", value);
                            setRefundData((prevData) => ({
                              ...prevData,
                              Mode: value,
                            }));
                          }}
                          sx={{
                            size: "lg",
                            width: 150,
                            fontSize: 16,
                            marginLeft: 1,
                            marginRight: 1,
                          }}
                        >
                          {modes.map((option, i) => (
                            <Option
                              sx={{ fontSize: 16 }}
                              key={i}
                              value={option.Mode}
                            >
                              {option.Mode}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      {inputField("Reference", "Reference")}
                    </div>
                    <div className="row d-flex align-items-center">
                      {inputField("Refund", "Refund")}
                      {inputField("Fees", "Fees")}
                      {inputField("Net refund", "NetRefund", true, true)}
                    </div>
                    <div className="row align-items-center">
                      {inputField("Remarks", "Remarks")}
                    </div>
                  </Box>
                </Card>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitRefund}
                disabled={!refundData.NetRefund}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterRefund;
