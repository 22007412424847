import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";

import { QueryError, QueryLoading } from "./QueryStatus";

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";

import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {
  Tabs,
  TabList,
  TabPanel,
  Sheet,
  Table,
  Typography,
  tabClasses,
  Tab,
} from "@mui/joy";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { confirmDialog } from "../components/ConfirmDialog";

export default function Repayment() {
  const axiosPrivate = useAxiosPrivate();
  const [openLoading, setOpenLoading] = useState(false);
  const [repayments, setRepayments] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [pno, setPno] = useState(1);
  const [year, setYear] = useState(2023);
  const [dateRange, setDateRange] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  const [department, setDepartment] = useState("All Departments");
  const [allDepartments, setAllDepartments] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    async function fetchData() {
      let response;
      response = await axiosPrivate.get("/departments");
      console.log("Departments:", response.data);
      setAllDepartments(response.data);
    }
    fetchData();
  }, []);

  const deleteRepayment = async (repayment) => {
    const response = await axiosPrivate.post("/repayments/deleteRepayment", {
      TRANSACTIONID: repayment.TRANSACTIONID,
      PayNo: repayment.PayNo,
      Amount: repayment.Amount,
    });
    console.log(`Delete repayment ${repayment.PayNo} returned: ${response}`);
    handleSearch();
  };

  const columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "TRANSACTIONID",
      flex: 3,
      editable: true,
    },
    { field: "CIFKEY", headerName: "CIFKEY", flex: 3, editable: true },
    {
      field: "RepaymentDate",
      headerName: "Repayment Date",
      flex: 5,
      editable: true,
    },
    { field: "PayNo", headerName: "Pay No", flex: 3, editable: true },
    { field: "Amount", headerName: "Amount", flex: 4, editable: true },
    { field: "Fee", headerName: "Fee", flex: 4, editable: true },
    { field: "Net", headerName: "Net", flex: 4, editable: true },
    { field: "Remarks", headerName: "Remarks", flex: 6, editable: true },
    {
      field: "action",
      headerName: "Action",
      flex: 6,
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete bank", params);
          confirmDialog(
            `Do you really want to delete repayment ${params.row.Amount} for ${params.row.PayNo}?`,
            () => deleteRepayment(params.row)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    console.log("Tab value", newValue);
  };

  const handleSearch = async () => {
    console.log("handleSearch", activeTab);
    setRepayments([]);
    setTotalAmount(0);
    setTotalFee();
    setTotalNet(0);
    let results;
    if (activeTab === 0) {
      if (pno && year) {
        const pnoYear =
          pno.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          year.toString().slice(-2);
        console.log("pnoYear:", pnoYear);
        const response = await axiosPrivate.post("/repayments/searchByPno", {
          PayNo: pnoYear,
          Department: department,
        });
        results = response.data;
        setRepayments(response.data);
      }
    } else if (activeTab === 1) {
      if (fromDate !== "" && toDate !== "") {
        console.log(`Date Range ${fromDate} to ${toDate}`);
        const response = await axiosPrivate.post(
          "/repayments/searchByDateRange",
          {
            FromDate: fromDate,
            ToDate: toDate,
            Department: department,
          }
        );
        results = response.data;
        setRepayments(response.data);
      }
    }

    let tAmount = 0;
    let tFee = 0;
    let tNet = 0;

    results.map((item) => {
      tAmount += item.Amount;
      tFee += item.Fee;
      tNet += item.Net;
    });
    setTotalAmount(tAmount.toFixed(2));
    setTotalFee(tFee.toFixed(2));
    setTotalNet(tNet.toFixed(2));
  };

  const allDepartmentProps = {
    options: ["All Departments"].concat(
      allDepartments.map((option) => `${option.DeptName}-${option.DeptCom}`)
    ),
  };

  const onDepartmentChange = (event, values) => {
    //console.log("Auto", event, values);
    setDepartment(values);
  };

  const fromDateChange = (value, error) => {
    console.log("fromDate", value, error);
    if (!error.validationError) {
      const dateString = new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      console.log("fromDate string:", dateString);
      setFromDate(dateString);
    }
  };

  const toDateChange = (value, error) => {
    console.log("toDateChange", value);
    if (!error.validationError) {
      const dateString = new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      console.log("toDate string:", dateString);
      setToDate(dateString);
    }
  };

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <Dialog open={openLoading}>
        <DialogContent>
          <QueryLoading />
        </DialogContent>
      </Dialog>
      <h3>Search Repayments</h3>

      <Tabs
        size="sm"
        aria-label="Pricing plan"
        defaultValue={0}
        onChange={handleTabChange}
        sx={(theme) => ({
          height: 220,
          width: "95%",
          "--Tabs-gap": "0px",
          //          borderRadius: "lg",
          boxShadow: "sm",
          //          overflow: "clip",
          border: `1px solid ${theme.vars.palette.divider}`,
        })}
      >
        <TabList
          sx={{
            "--ListItem-radius": "0px",
            borderRadius: 0,
            [`& .${tabClasses.root}`]: {
              fontWeight: "lg",
              flex: 1,
              bgcolor: "background.body",
              position: "relative",
              borderBottom: "1px solid lightgray",
              [`&.${tabClasses.selected}`]: {
                color: "primary.500",
              },
              [`&.${tabClasses.selected}:before`]: {
                content: '""',
                display: "block",
                position: "absolute",
                bottom: -1,
                width: "100%",
                height: 3,
                bgcolor: "primary.400",
              },
              [`&.${tabClasses.focusVisible}`]: {
                outlineOffset: "-3px",
              },
            },
          }}
        >
          <Tab sx={{ py: 1.5 }}>By PNO</Tab>
          <Tab>By Date Range</Tab>
        </TabList>
        <TabPanel value={0} sx={{ p: 3 }}>
          <div className="d-flex flex-row align-items-center">
            <h6>Pay Period (PNO):</h6>
            <Select
              defaultValue={1}
              onChange={(e, value) => setPno(value)}
              sx={{
                size: "lg",
                width: 100,
                fontSize: 16,
                marginLeft: 2,
                marginRight: 4,
              }}
            >
              {[...Array(27)].map((_, i) => (
                <Option sx={{ fontSize: 16 }} key={i} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
            <h6>Year:</h6>
            <Select
              defaultValue={2023}
              onChange={(e, value) => {
                setYear(value);
              }}
              sx={{
                size: "sm",
                width: 130,
                fontSize: 16,
                marginLeft: 2,
                marginRight: 4,
              }}
            >
              {[...Array(10)].map((_, i) => (
                <Option
                  sx={{ fontSize: 16 }}
                  key={i}
                  value={new Date().getFullYear() - i}
                >
                  {new Date().getFullYear() - i}
                </Option>
              ))}
            </Select>
          </div>
        </TabPanel>
        <TabPanel value={1} sx={{ p: 3 }}>
          <div className="d-flex flex-row align-items-center">
            <h5 style={{ marginRight: 8 }}>Date Range</h5>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                onChange={fromDateChange}
                size="small"
                label="From"
                format="YYYY-MM-DD"
              />
            </LocalizationProvider>
            <h5 className="mx-2"> - </h5>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                onChange={toDateChange}
                size="small"
                label="To"
                format="YYYY-MM-DD"
              />
            </LocalizationProvider>
          </div>
        </TabPanel>

        <div className="d-flex flex-row mx-4 pb-2 align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <h5 style={{ color: "#e24f05" }}>Includes</h5>
            <Autocomplete
              {...allDepartmentProps}
              disableClearable
              value={department}
              id="Department"
              name="Department"
              isOptionEqualToValue={(option, value) =>
                value === undefined || value === "" || option === value
              }
              sx={{ width: 400, marginLeft: 2 }}
              onChange={onDepartmentChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={department}
                  label="Department"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </div>
          <Button
            style={{
              borderRadius: 10,
              width: 250,
              margin: 15,
            }}
            variant="contained"
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Tabs>

      {/* <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center">
          <h5>Search repayments </h5>
          <Select
            defaultValue="PNO"
            onChange={handleTabChange}
            className="mx-2"
          >
            <Option value="PNO">by PNO</Option>
            <Option value="DATES">by Dates</Option>
            <Option value="DEPARTMENT">by Department</Option>
          </Select>
          <h5>:</h5>
        </div>

        <Card sx={{ width: "95%" }}>
          <CardHeader></CardHeader>
          <CardContent>
            <div role="tabpanel" hidden={activeTab !== "PNO"}></div>
            <div role="tabpanel" hidden={activeTab !== "DATES"}>
              By Dates{" "}
            </div>
            <div role="tabpanel" hidden={activeTab !== "DEPARTMENT"}>
              By Department{" "}
            </div>
          </CardContent>
          <CardActions>
            <Button size="small" variant="contained">
              Search
            </Button>
          </CardActions>
        </Card>
      </div> */}

      {/* <Sheet>
        <Table
          borderAxis="xBetween"
          color="primary"
          size="sm"
          stickyFooter
          stickyHeader
          variant="outlined"
          className="mr-2"
        >
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>CIFKEY</th>
              <th>Repayment Date</th>
              <th>Pay No</th>
              <th>Amount</th>
              <th>Fee</th>
              <th>Net</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {repayments.map((row, i) => (
              <tr key={i}>
                <td>{row.TRANSACTIONID}</td>
                <td>{row.CIFKEY}</td>
                <td>{row.RepaymentDate}</td>
                <td>{row.PayNo}</td>
                <td>{row.Amount}</td>
                <td>{row.Fee}</td>
                <td>{row.Net}</td>
                <td>{row.Remarks}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet> */}

      <div className="mt-3 d-flex flex-row align-items-center">
        <h5>Results: </h5>
        <div
          style={{ borderRadius: 10, width: "85%" }}
          className="d-flex flex-row mx-2 border border border-3 border-info pt-3 p-2 justify-content-between align-items-center"
        >
          <h6>
            <strong>Total Amount:</strong> {totalAmount}
          </h6>

          <h6>
            {" "}
            <strong>Total Fee:</strong> {totalFee}
          </h6>
          <h6>
            {" "}
            <strong>Total net repayment:</strong> {totalNet}
          </h6>
        </div>
      </div>
      <Box sx={{ height: 300, width: "95%" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "RepaymentDate", sort: "desc" }],
            },
          }}
          rowHeight={25}
          rows={repayments}
          getRowId={(row) => uuid()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </Stack>
  );
}
