import { CircularProgress } from "@mui/material";
import { MdOutlineRunningWithErrors } from "react-icons/md";
export function QueryLoading() {
  return (
    <div className="d-flex flex-column align-items-center w-100 justify-content-center">
      <CircularProgress />
      <h5 className="mt-1">Loading...</h5>
    </div>
  );
}

export function QueryError(error) {
  return (
    <div className="d-flex flex-column align-items-center w-100 justify-content-center">
      <MdOutlineRunningWithErrors style={{ color: "red" }} />
      <h5 className="mt-1">Error....{error.message}</h5>
    </div>
  );
}
