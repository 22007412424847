import { Autocomplete, Box, Button, Card, TextField } from "@mui/material";

import { useEffect, useState, useRef } from "react";
import { getPnoArray } from "../utils/helper";
import bootstrap from "bootstrap/dist/js/bootstrap.min";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LoanSummary from "./LoanSummary";

function EnterAdjustment({ loanData, refetchAdjustment }) {
  const axiosPrivate = useAxiosPrivate();
  const modalRef = useRef();

  const initialFormState = {
    TRANSACTIONID: loanData.TRANSACTIONID,
    CIFKEY: loanData.CIFKEY,
    Adjustment: "",
    Remarks: "",
    Code: "ADJ",
  };
  const [adjustmentData, setAdjustmentData] = useState(initialFormState);
  //  console.log("EnterAdjustment loanData", loanData, refetchAdjustment);

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setAdjustmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setAdjustmentData((prevData) => ({
      ...prevData,
      NetAdjustment: adjustmentData.Adjustment - adjustmentData.Fees,
    }));
  }, [adjustmentData.Adjustment, adjustmentData.Fees]);

  const inputField = (label, name, required = false, readOnly = false) => {
    return (
      <TextField
        id={name}
        name={name}
        label={label}
        variant="outlined"
        size="small"
        className="col m-2"
        value={adjustmentData[name]}
        onChange={handleChange}
        required={required}
        inputProps={{ readOnly: readOnly }}
      />
    );
  };

  useEffect(() => {
    setAdjustmentData(initialFormState);
  }, [loanData]);

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new bootstrap.Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrap.Modal.getInstance(modalEle);
    setAdjustmentData(initialFormState);
    bsModal.hide();
  };

  const submitAdjustment = async (e) => {
    e.preventDefault();

    let submitData = {
      CIFKEY: loanData.CIFKEY,
      TRANSACTIONID: loanData.TRANSACTIONID,

      Adjustment: adjustmentData.Adjustment,
      Remarks: adjustmentData.Remarks,
      Code: "ADJ",
    };
    console.log("submitting adjustment...", submitData);

    const response = await axiosPrivate.post(
      "/adjustments/addAdjustment",
      submitData
    );
    console.log("Add Adjustment Returned:", response);
    if (response) {
      refetchAdjustment();
      hideModal();
    }
  };

  return (
    <div>
      <Button
        style={{ width: "275px" }}
        variant="contained"
        size="small"
        onClick={showModal}
      >
        Enter Adjustment
      </Button>

      <div className="modal fade" ref={modalRef} tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Enter Adjustment
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <LoanSummary loanData={loanData} />

                <Card sx={{ padding: 1, marginBottom: 2 }}>
                  <Box container m={2}>
                    <div className="row d-flex align-items-center">
                      {inputField("Adjustment", "Adjustment", true)}
                    </div>
                    <div className="row align-items-center">
                      {inputField("Remarks", "Remarks", true)}
                    </div>
                  </Box>
                </Card>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitAdjustment}
                disabled={!adjustmentData.Adjustment || !adjustmentData.Remarks}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterAdjustment;
