import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { v4 as uuid } from "uuid";
import EnterDefault from "./EnterDefault";
import { confirmDialog } from "./ConfirmDialog";
import { axiosPrivate } from "../api/axios";
import { Button, Stack } from "@mui/material";

function DefaultList({ data, loanData, refetchDefault }) {
  const deleteDefault = async (defaultData) => {
    const response = await axiosPrivate.post("/defaults/deleteDefault", {
      TRANSACTIONID: defaultData.TRANSACTIONID,
      DefaultDate: defaultData.DefaultDate,
      Default: defaultData.Default,
    });
    console.log(`Delete default ${defaultData.PayNo} returned: ${response}`);
    refetchDefault();
  };

  const columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "Transaction ID",
      flex: 4,
      editable: true,
    },
    {
      field: "DefaultDate",
      headerName: "Default Date",
      flex: 7,
      editable: true,
    },
    { field: "Default", headerName: "Default", flex: 4, editable: true },
    {
      field: "Mode",
      headerName: "Mode",
      flex: 5,
      editable: true,
    },
    {
      field: "PayNo",
      headerName: "Pay No",
      flex: 3,
      editable: true,
    },
    { field: "Remarks", headerName: "Remarks", flex: 8, editable: true },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete bank", params);
          confirmDialog(
            `Do you really want to delete default ${params.row.Default} for ${params.row.PayNo}?`,
            () => deleteDefault(params.row)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <DataGrid
        style={{ flex: 2, height: "300px" }}
        rowHeight={25}
        rows={data}
        getRowId={(row) => uuid()}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 700 },
          },
        }}
      />
      <div className="d-flex flex-row justify-content-center my-2">
        <EnterDefault
          loanData={loanData}
          refetchDefault={refetchDefault}
          sx={{ margin: 10 }}
        />
      </div>
    </div>
  );
}

export default DefaultList;
