import {
  Autocomplete,
  Box,
  Button,
  Card,
  Stack,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback, Fragment } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { confirmDialog } from "../components/ConfirmDialog";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddClient() {
  const axiosPrivate = useAxiosPrivate();
  const { state } = useLocation();
  console.log("Receiving state", state);
  const navigate = useNavigate();

  const [departments, setDepartments] = useState([]);

  const initialFormState = {
    CreationDate: "",
    CIFKEY: "",
    Department: "",
    DeptCom: "",
    Title: "",
    ClientName: "",
    Gender: "",
    DateOfBirth: "",
    Occupation: "",
    Employer: "",
    Add1: "",
    Add2: "",
    Add3: "",
    NextLeaveDate: "",
    Duration: "",
    BusPhone: "",
    BusFax: "",
    Email: "",
    NoOfDependent: "",
    MaritalStatus: "",
    CurrResiAdd: "",
    Suburb: "",
    Section: "",
    Lot: "",
    HomePhone: "",
    HomeProvince: "",
    District: "",
    Village: "",

    AccNo: "",
    TraType: "",
    BankName: "",
    BankNameCode: "",
    BankBranch: "",
    BankBranchCode: "",
    Remarks: "",
  };

  const [clientData, setClientData] = useState(
    state ? state : initialFormState
  );

  console.log("client Data:", clientData);

  const [banks, setBanks] = useState([]);
  const [bankBranches, setBankBranches] = useState([]);
  const [curBranches, setCurBranches] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputBranchValue, setInputBranchValue] = useState("");
  const [bankNameOptions, setBankNameOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const [branchKey, setBranchKey] = useState("somekey");

  const [bod, setBod] = useState();

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    async function fetchData() {
      let response;
      response = await axiosPrivate.get("/departments");
      console.log("Departments:", response.data);
      setDepartments(response.data);

      response = await axiosPrivate.get("/banks");
      console.log("Banks:", response.data);
      setBanks(response.data);

      response = await axiosPrivate.get("/banks/branches");
      console.log("Branches:", response.data);
      setBankBranches(response.data);

      if (state) {
        const bodDate = new Date(clientData.DateOfBirth);
        setBod(bodDate);
        console.log("Add client with state:", state.DateOfBirth, bodDate);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    let active = true;

    if (!open) {
      return undefined;
    }

    (async () => {
      //        await sleep(1e3); // For demo purposes.

      if (active) {
        let thisBankBranches = [];
        bankBranches.forEach((branch) => {
          if (branch.BankCode === clientData.BankNameCode) {
            thisBankBranches.push(branch.BranchName);
          }
        });
        console.log("Current Bank branches:", thisBankBranches);
        setCurBranches(thisBankBranches);
      }
    })();

    return () => {
      active = false;
    };
  }, [open]);

  const departmentProps = {
    options: departments.map((option) => option.DeptName),
  };

  const onDepartmentChange = (event, values) => {
    //console.log("Auto", event, values);
    setClientData((prevData) => ({
      ...prevData,
      Department: values,
    }));
    let found = departments.find((o) => {
      return values === o.DeptName;
    });
    if (found) {
      console.log("Found department", found.DeptCom);
      setClientData((prevData) => ({
        ...prevData,
        DeptCom: found.DeptCom,
      }));
    }
  };

  const onBankNameChange = (event, values) => {
    console.log("BANK NAME - new value", event, values);
    setClientData((prevData) => ({
      ...prevData,
      BankName: values,
    }));

    // Find bank code
    let found = banks.find((o) => {
      return values === o.BankName;
    });

    let bankCode = found.BankCode;
    console.log("FOUND BANK CODE:", bankCode, bankBranches);
    setClientData((prevData) => ({
      ...prevData,
      BankNameCode: bankCode,
    }));

    // Reset Branch Name
    setCurBranches([]);
    setClientData((prevData) => ({
      ...prevData,
      BankBranchName: "",
    }));
    setInputBranchValue("");

    setBranchKey(bankCode);
  };

  const inputField = (label, name, required = false) => {
    return (
      <TextField
        autoComplete="none"
        inputProps={{
          autoComplete: "none",
        }}
        id={name}
        name={name}
        label={label}
        variant="outlined"
        size="small"
        className="flex-fill"
        onChange={handleChange}
        value={clientData[name]}
        required={required}
      />
    );
  };

  const deleteClient = async (e) => {
    e.preventDefault();
    console.log("Deleting client:", clientData);

    const response = await axiosPrivate.post("/clients/deleteClient", {
      CIFKEY: clientData.CIFKEY,
    });
    console.log("Delete Client Returned:", response);

    navigate("/clientList");
  };

  const submitForm = async (e) => {
    e.preventDefault();
    console.log("Submit form data:", clientData);

    if (state) {
      // Update Client
      const response = await axiosPrivate.put(
        "/clients/updateClient",
        clientData
      );
      console.log("Update Client Returned:", response);
    } else {
      // Add Client
      const response = await axiosPrivate
        .post("/clients/addClient", clientData)
        .catch((error) => {
          console.log("Add client error", error);
          confirmDialog(
            `Client CIFKEY: ${clientData.CIFKEY} already exists.`,
            () => {},
            true
          );
        });
      console.log("Add Client Returned:", response);
    }
    navigate("/clientList");
  };

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <h1>Client Details</h1>

      <form onSubmit={submitForm}>
        <div>
          <Card sx={{ padding: 1, marginBottom: 2, width: "95%" }}>
            <h5>Personal Info</h5>
            <div>
              <div className="d-flex flex-row flex-fill w-100 gap-3 align-items-center">
                {inputField("Client Name", "ClientName", true)}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    className="flex-fill"
                    required
                    inputFormat="YYYY-MM-DD"
                    value={dayjs(clientData.DateOfBirth)}
                    onChange={(value, error) => {
                      if (!error.validationError) {
                        setClientData((prevData) => ({
                          ...prevData,
                          DateOfBirth: new Date(value)
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " "),
                        }));
                      }
                    }}
                    size="small"
                    label="Date of Birth"
                    format="YYYY-MM-DD"
                  />
                </LocalizationProvider>

                <Autocomplete
                  options={["Male", "Female"]}
                  disableClearable
                  value={clientData.Gender}
                  id="Gender"
                  name="Gender"
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === "" || option === value
                  }
                  sx={{}}
                  className="flex-fill"
                  onChange={(event, value) =>
                    setClientData((prevData) => ({
                      ...prevData,
                      Gender: value,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={clientData.Gender}
                      label="Gender"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                <Autocomplete
                  options={[
                    "Single",
                    "Married",
                    "Divorce",
                    "Widowed",
                    "Unknown",
                  ]}
                  disableClearable
                  value={clientData.MaritalStatus}
                  id="MaritalStatus"
                  name="MaritalStatus"
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === "" || option === value
                  }
                  sx={{}}
                  className="flex-fill"
                  onChange={(event, value) =>
                    setClientData((prevData) => ({
                      ...prevData,
                      MaritalStatus: value,
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={clientData.MaritalStatus}
                      label="Marital Status"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </div>
            </div>
          </Card>

          <Card sx={{ padding: 1, marginBottom: 2, width: "95%" }}>
            <h5>Employment</h5>
            <div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                {inputField("Employer", "Employer", true)}

                {inputField("CIFKEY", "CIFKEY", true)}

                {inputField("Occupation", "Occupation")}
              </div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                <Autocomplete
                  className="flex-fill"
                  {...departmentProps}
                  disableClearable
                  value={clientData.DeptName}
                  id="Department"
                  name="Department"
                  defaultValue={null}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === "" || option === value
                  }
                  onChange={onDepartmentChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                {inputField("Department Code", "DeptCom", true)}

                {inputField("Business Address 1", "Add1")}
              </div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                {inputField("Business Address 2", "Add2")}
                {inputField("Business Phone", "BusPhone")}
                {inputField("Business Fax", "BusFax")}
              </div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                {inputField("Email Address", "Email")}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    className="flex-fill"
                    value={dayjs(clientData.NextLeaveDate)}
                    onChange={(value, error) => {
                      if (!error.validationError) {
                        setClientData((prevData) => ({
                          ...prevData,
                          NextLeaveDate: new Date(value)
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " "),
                        }));
                      }
                    }}
                    size="small"
                    label="Next Leave Date"
                    format="YYYY-MM-DD"
                  />
                </LocalizationProvider>

                {inputField("Leave Duration (Days)", "Duration")}
              </div>
            </div>
          </Card>
          <Card sx={{ padding: 1, marginBottom: 2, width: "95%" }}>
            <h5>Resident</h5>
            <div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                {inputField("Current Resident Address", "CurrResiAdd", true)}
                {inputField("Suburb", "Suburb")}
              </div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                {inputField("Section", "Section")}

                {inputField("Lot", "Lot")}
                {inputField("Home Phone", "HomePhone")}
              </div>
              <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
                {inputField("Home Province", "HomeProvince")}
                {inputField("District", "District")}
                {inputField("Village", "Villige")}
              </div>
            </div>
          </Card>

          <Card sx={{ padding: 1, marginBottom: 2, width: "95%" }}>
            <h5>Bank Info</h5>

            <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
              <Autocomplete
                options={banks.map((option) => option.BankName)}
                disableClearable
                value={clientData.BankName}
                id="BankName"
                name="BankName"
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === "" || option === value
                }
                className="flex-fill"
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onChange={onBankNameChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bank Name"
                    variant="outlined"
                    size="small"
                  />
                )}
              />

              <Autocomplete
                options={curBranches}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                disableClearable
                key={branchKey}
                value={clientData.BankBranchName}
                id="BankBranch"
                name="BankBranch"
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === "" || option === value
                }
                className="flex-fill"
                onInputChange={(e, newInputValue) =>
                  setInputBranchValue(newInputValue)
                }
                onChange={(e, newValue) =>
                  setClientData((prevData) => ({
                    ...prevData,
                    BankBranchName: newValue,
                  }))
                }
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Bank Branch" />
                )}
              />
              {inputField("Account Type", "TraType")}
            </div>
            <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
              {inputField("Account No.", "AccNo")}
              {inputField("Remarks", "Remarks")}
            </div>
          </Card>
        </div>
        <div className="m-3 d-flex justify-content-end">
          {state && (
            <Button
              className="mx-3"
              color="warning"
              variant="contained"
              type="button"
              onClick={(e) => {
                confirmDialog(
                  `Do you really want to delete client: ${clientData.ClientName}?`,
                  () => deleteClient(e)
                );
              }}
            >
              Delete Client
            </Button>
          )}
          <Button variant="contained" type="submit">
            {state ? "Update Client" : "Add Client"}
          </Button>
        </div>
      </form>
    </Stack>
  );
}

export default AddClient;
