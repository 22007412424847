export function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

function getFirstPNODate(year) {
  if (year < 2023) return false;
  // start from 2023
  const firstFortnightByYear = [12, 11, 9];

  const day = firstFortnightByYear[year - 2023];

  let firstPnoDate = new Date(`${year}-1-${day}`);

  // let firstPnoDate = new Date(`${year}-1-1`);
  // let dayOfYear = firstPnoDate.getDay();

  // if (dayOfYear > 4) {
  //   firstPnoDate = firstPnoDate.setDate(
  //     firstPnoDate.getDate() + (7 - (dayOfYear - 4))
  //   );
  // } else if (dayOfYear < 4) {
  //   firstPnoDate = firstPnoDate.setDate(
  //     firstPnoDate.getDate() + (4 - dayOfYear)
  //   );
  // }

  //console.log("first Thursday:", firstPnoDate.getDate());

  return firstPnoDate;
}

export function getCurrentPNO() {
  // Get the day of first Wednesday of the year
  let date1 = new Date();
  let year = date1.getFullYear();

  let date2 = new Date(getFirstPNODate(year));
  console.log(
    "First PNO of the year",
    date2.toISOString().slice(0, 19).replace("T", " ")
  );

  const diffDays = Math.ceil(
    (Math.abs(date1 - date2) + 1) / (1000 * 60 * 60 * 24)
  );

  //console.log(diffDays + " days");

  let fortnight = Math.ceil(diffDays / 14);
  console.log(`Fortnight ${fortnight} of ${year}`);

  // next fortnight
  fortnight++;

  return fortnight;
}

export function getDateFromPNO(PNO) {
  let arr = PNO.split("-");
  let pno = parseInt(arr[0].slice(-2));
  let year = parseInt(arr[1]);
  let pnoDate = new Date(getFirstPNODate(year));
  //console.log("firstPnoOfYear", pnoDate);

  pnoDate = pnoDate.setDate(pnoDate.getDate() + (pno - 1) * 14);

  //  console.log(pno, year, pnoDate);
  return pnoDate;
}

export function getPnoArray(startPNO, numPNO, PPE) {
  let pnoArray = [];
  let thisYear = new Date().getFullYear();
  let pnoYear = PPE ? PPE : thisYear;
  pnoYear = parseInt(pnoYear.toString().slice(2, 4));
  console.log("pnoYear", pnoYear);
  let pno = parseInt(startPNO);
  for (var i = 0; i < numPNO; i++) {
    pno = pno.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    pnoArray.push(`${pno}-${pnoYear}`);

    pno++;
    if (pno > 26) {
      pno = 1;
      pnoYear++;
    }
  }

  console.log("PNO Array", pnoArray);
  return pnoArray;
}

export function getInterestRate(LoanAmount, NOF) {
  const rates = [
    -1, -1, -1, -1, 33, 33, 33, 34, 35, 36, 37, 39, 41, 43, 45, 45, 45, 45, 45,
    45, 45, 45, 45, 45, 45, 45, 45,
  ];

  if (
    (LoanAmount <= 500 && NOF > 6) ||
    (LoanAmount <= 1000 && NOF > 10) ||
    (LoanAmount <= 2000 && NOF > 14) ||
    LoanAmount < 300 ||
    NOF < 4 ||
    NOF > 26
  ) {
    return -1;
  }

  return rates[NOF];
}
