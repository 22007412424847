import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import {
  gridClasses,
  DataGrid,
  GridToolbar,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { Alert, Button, Stack } from "@mui/material";
import { FiRefreshCcw, FiEdit } from "react-icons/fi";
import { Navigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { AddBank } from "../components/AddBank";
import { AddBranch } from "../components/AddBranch";
import { QueryError, QueryLoading } from "./QueryStatus";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { confirmDialog } from "../components/ConfirmDialog";

export default function Bank() {
  const axiosPrivate = useAxiosPrivate();
  const [banks, setBanks] = useState([]);
  const [branches, setBranches] = useState([]);
  const [bankCode, setBankCode] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axiosPrivate.get("/banks");
      setBanks(response.data);
    }
    fetchData();
  }, []);

  const ODD_OPACITY = 0.2;

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  const bank_columns: GridColDef[] = [
    { field: "BankCode", headerName: "Bank Code", flex: 2 },
    {
      field: "BankShort",
      headerName: "Bank Short",
      flex: 3,
      editable: true,
    },
    {
      field: "BankName",
      headerName: "Bank Name",
      flex: 4,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete bank", params);
          confirmDialog(
            `Do you really want to delete bank: ${params.row.BankName}?`,
            () => deleteBank(params.row.BankCode)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const branch_columns: GridColDef[] = [
    { field: "BankCode", headerName: "Bank Code", flex: 2 },

    { field: "BranchCode", headerName: "Branch Code", flex: 2 },
    {
      field: "BranchName",
      headerName: "Branch Name",
      flex: 4,
      editable: true,
    },
    {
      field: "Xcode",
      headerName: "Xcode",
      flex: 2,
      editable: true,
    },
    {
      field: "BankBranchCode",
      headerName: "Bank Branch Code",
      flex: 3,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete bank", params);
          confirmDialog(
            `Do you really want to delete branch: ${params.row.BranchName}?`,
            () => deleteBranch(params.row.BankCode, params.row.BankBranchCode)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handleOnRowClick = async (rowData) => {
    console.log("Clicked ->" + rowData.BankCode);
    setBankCode(rowData.BankCode);
    fetchBranches(rowData.BankCode);
  };

  const fetchBranches = async (bankCode) => {
    const response = await axiosPrivate.get(`/banks/branches/${bankCode}`);
    setBranches(response.data);
  };

  async function refetchAllBanks() {
    console.log("Doing refetch...");
    const response = await axiosPrivate.get("/banks");
    setBanks(response.data);
  }

  const deleteBank = async (bankCode) => {
    const response = await axiosPrivate.post("/banks/deleteBank", {
      BankCode: bankCode,
    });
    console.log(`Delete Bank ${bankCode} returned: ${response}`);
    refetchAllBanks();
  };

  const deleteBranch = async (bankCode, bankBranchCode) => {
    const response = await axiosPrivate.post("/banks/deleteBranch", {
      BankBranchCode: bankBranchCode,
    });
    console.log(`Delete Branch ${bankBranchCode} returned: ${response}`);
    fetchBranches(bankCode);
  };

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <Box className="d-flex flex-row justify-content-between">
        <h2>
          Bank
          <Button onClick={refetchAllBanks}>
            <FiRefreshCcw />
          </Button>
        </h2>
        <div className="mx-5 my-2">
          <AddBank refreshBank={refetchAllBanks} />
        </div>
      </Box>

      <Box sx={{ height: 250, width: "95%" }}>
        <StripedDataGrid
          onRowClick={(param) => handleOnRowClick(param.row)}
          rowHeight={25}
          rows={banks}
          getRowId={(row) => row.BankCode}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={bank_columns}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
      </Box>

      <Box className="d-flex flex-row justify-content-between">
        <h3>
          Branches
          <Button onClick={() => fetchBranches(bankCode)}>
            <FiRefreshCcw />
          </Button>
        </h3>
        <div className="mx-5">
          <AddBranch bankCode={bankCode} refreshBranch={fetchBranches} />
        </div>
      </Box>

      <Box sx={{ height: 250, width: "95%" }}>
        <DataGrid
          rowHeight={25}
          rows={branches}
          getRowId={(row) => uuid()}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={branch_columns}
        />
      </Box>
    </Stack>
  );
}
