import { TextField } from "@mui/material";
import bootstrap from "bootstrap/dist/js/bootstrap.min";
import { useRef, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export function AddBank({ refreshBank }) {
  const axiosPrivate = useAxiosPrivate();
  const modalRef = useRef();
  const [bankName, setBankName] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankShort, setBankShort] = useState("");

  const showModal = () => {
    const modalEle = modalRef.current;
    const bsModal = new bootstrap.Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = modalRef.current;
    const bsModal = bootstrap.Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const submitBank = async (e) => {
    e.preventDefault();

    console.log(bankName, bankCode, bankShort);
    let submitData = {
      BankName: bankName,
      BankCode: bankCode,
      BankShort: bankShort,
    };

    const response = await axiosPrivate.post("/banks/addBank", submitData);
    console.log("Add Bank Returned:", response);
    setBankName("");
    setBankCode("");
    setBankShort("");
    refreshBank();
    hideModal();
  };

  return (
    <div>
      <button type="button" className="btn btn-primary" onClick={showModal}>
        Add Bank
      </button>
      <div className="modal fade" ref={modalRef} tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add New Bank
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <section>
                <TextField
                  id="BankName"
                  name="BankName"
                  label="Bank Name"
                  required
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
                <TextField
                  id="BankCode"
                  name="BankCode"
                  label="Bank Code"
                  required
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={bankCode}
                  onChange={(e) => setBankCode(e.target.value)}
                />
                <TextField
                  id="BankShort"
                  name="BankShort"
                  label="Bank Short Name"
                  required
                  variant="outlined"
                  size="small"
                  className="col m-2"
                  value={bankShort}
                  onChange={(e) => setBankShort(e.target.value)}
                />
              </section>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitBank}
                disabled={!bankCode || !bankName || !bankShort}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
