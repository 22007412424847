import { Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { v4 as uuid } from "uuid";
import { confirmDialog } from "./ConfirmDialog";
import { axiosPrivate } from "../api/axios";
import EnterRefund from "./EnterRefund";

function RefundList({ data, loanData, refetchRefund }) {
  const deleteRefund = async (refund) => {
    const response = await axiosPrivate.post("/refunds/deleteRefund", {
      TRANSACTIONID: refund.TRANSACTIONID,
      RefundDate: refund.RefundDate,
      Refund: refund.Refund,
    });
    console.log(`Delete refund ${refund.PayNo} returned: ${response}`);
    refetchRefund();
  };

  const columns: GridColDef[] = [
    {
      field: "TRANSACTIONID",
      headerName: "Transaction ID",
      flex: 5,
      editable: true,
    },
    {
      field: "RefundDate",
      headerName: "Refund Date",
      flex: 7,
      editable: true,
    },
    { field: "Mode", headerName: "Pay Mode", flex: 6, editable: true },
    {
      field: "Refund",
      headerName: "Refund Amount",
      flex: 7,
      editable: true,
    },
    { field: "Fees", headerName: "Fees", flex: 7, editable: true },
    {
      field: "NetRefund",
      headerName: "Net Refund",
      flex: 7,
      editable: true,
    },
    { field: "Remarks", headerName: "Remarks", flex: 8, editable: true },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => {
        const onClick = (e) => {
          console.log("delete bank", params);
          confirmDialog(
            `Do you really want to delete refund ${params.row.Refund} for ${params.row.RefundDate}?`,
            () => deleteRefund(params.row)
          );
        };

        return (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={onClick}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <DataGrid
        style={{ flex: 2, height: "300px" }}
        rowHeight={25}
        rows={data}
        getRowId={(row) => uuid()}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 700 },
          },
        }}
      />
      <div className="d-flex flex-row justify-content-center my-2">
        <EnterRefund loanData={loanData} refetchRefund={refetchRefund} />
      </div>
    </div>
  );
}

export default RefundList;
