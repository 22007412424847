import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import mainLogo from "./../assets/logo512.png";
import useToggle from "../hooks/useToggle";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useLogout from "../hooks/useLogout";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ChangePassword() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [vPassword, setvPassword] = useState("");
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("User", auth.user);

    try {
      const response = await axiosPrivate.post(
        "/changePassword",
        JSON.stringify({ user: auth.user, pwd: password })
      );
      console.log("response:", response.data);
      signOut();
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Change Password Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <Box component="main" maxWidth="xs" className="m-3">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>

            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="off"
                autoFocus
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="vPassword"
                label="Verify Password"
                type="vPassword"
                id="vPassword"
                autoComplete="off"
                onChange={(e) => setvPassword(e.target.value)}
              />

              <Button
                disabled={password === "" || password !== vPassword}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Change Password
              </Button>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
