import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { MdExpandMore } from "react-icons/md";
import { useEffect, useState } from "react";
import {
  getCurrentPNO,
  getDateFromPNO,
  getInterestRate,
  getPnoArray,
  timeout,
} from "../utils/helper";
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import { v4 as uuid } from "uuid";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Option, Select } from "@mui/joy";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddLoan() {
  const axiosPrivate = useAxiosPrivate();
  const { state } = useLocation();
  console.log("Receiving state", state);
  const navigate = useNavigate();
  const [allClients, setAllClients] = useState([]);
  const [loanCategories, setLoanCategories] = useState([]);
  const [users, setUsers] = useState([
    "AloJ",
    "JimmyO",
    "KorokiM",
    "RickyH",
    "VincentL",
  ]);
  const [modes, setModes] = useState([]);
  const [pno, setPno] = useState("");
  const [repayments, setRepayments] = useState([]);

  const initialFormState = {
    ClientName: "",
    CIFKEY: "",
    TRANSACIONID: "",
    LoanCat: "",
    LoanAmount: null,
    Offset: 0,
    NetLoan: "",
    NOF: null,
    Interest: 0,
    Repayable: 0,
    Deduction: 0,
    SalesCode: "",
    Mode: null,
    Reference: "",
    PNO: "1",
    PPE: new Date().toISOString().slice(0, 19).replace("T", " "),
    CommissionRate: null,
    Commission: 0,
    Remarks: "",
    Code: "",
    AcountStatus: "",
  };
  const [loanData, setLoanData] = useState({ ...initialFormState, ...state });

  const handleChange = (e) => {
    const type = e.target.type;

    const name = e.target.name;

    const value = type === "checkbox" ? e.target.checked : e.target.value;

    console.log("type, name, value", type, name, value);

    setLoanData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (
      name === "LoanAmount" ||
      name === "Interest" ||
      name === "NOF" ||
      name === "Offset"
    ) {
      console.log(
        "Loan",
        loanData.LoanAmount,
        loanData.Interest,
        loanData.NOF,
        loanData.Offset
      );
    }
  };

  useEffect(() => {
    console.log(
      "Loan data in useEffect",
      loanData.LoanAmount,

      loanData.NOF,
      loanData.Offset,
      loanData.PNO,
      loanData.PPE
    );

    if (!loanData.PNO) return;

    if (!loanData.LoanAmount || !loanData.NOF) {
      console.log("Reset in useEffect");
      setLoanData((prevData) => ({
        ...prevData,
        Interest: 0,
      }));
      setLoanData((prevData) => ({
        ...prevData,
        Repayable: 0,
      }));
      setLoanData((prevData) => ({
        ...prevData,
        NetLoan: 0,
      }));

      setLoanData((prevData) => ({
        ...prevData,
        Deduction: 0,
      }));
      setRepayments([]);
      return undefined;
    }

    const rate = getInterestRate(loanData.LoanAmount, loanData.NOF);
    if (rate === -1) {
      return undefined;
    }

    console.log("loan amount and rate:", loanData.LoanAmount, rate);

    let interest = parseFloat(loanData.LoanAmount * rate) / 100;
    setLoanData((prevData) => ({
      ...prevData,
      Interest: interest.toFixed(2),
    }));

    let payable = parseFloat(
      parseFloat(loanData.LoanAmount) + interest + parseFloat(loanData.Offset)
    ).toFixed(2);
    console.log("Payable:", payable);
    let eachPayment = parseFloat(payable / loanData.NOF).toFixed(2);
    let netLoan = parseFloat(
      parseFloat(loanData.LoanAmount) - parseFloat(loanData.Offset)
    ).toFixed(2);
    setLoanData((prevData) => ({
      ...prevData,
      Repayable: payable,
    }));
    setLoanData((prevData) => ({
      ...prevData,
      NetLoan: netLoan,
    }));

    setLoanData((prevData) => ({
      ...prevData,
      Deduction: eachPayment,
    }));

    let pnoArray = getPnoArray(loanData.PNO, loanData.NOF, loanData.PPE);
    let paymentSchedule = [{ No: 0, Balance: payable }];

    console.log("pnoArray", pnoArray);
    pnoArray.forEach((pno, index) => {
      const row = {
        No: index + 1,
        PNO: pno,
        Repayment: eachPayment,
        Balance: parseFloat(payable - eachPayment * (index + 1)).toFixed(2),
      };
      paymentSchedule.push(row);

      //Set first PNO and PPE
      // if (index === 0) {
      //   setLoanData((prevData) => ({
      //     ...prevData,
      //     PNO: row.PNO,
      //   }));

      //   let datePPE = new Date(getDateFromPNO(row.PNO));
      //   setLoanData((prevData) => ({
      //     ...prevData,
      //     PPE: datePPE.toISOString().slice(0, 19).replace("T", " "),
      //   }));
      // }
    });
    setRepayments(paymentSchedule);
  }, [
    loanData.LoanAmount,
    loanData.NOF,
    loanData.Offset,
    loanData.PNO,
    loanData.PPE,
  ]);

  const columns: GridColDef[] = [
    {
      field: "No",
      headerName: "No.",
      width: 70,
      editable: true,
    },
    { field: "PNO", headerName: "PNO", width: 150, editable: true },
    { field: "Repayment", headerName: "Repayment", width: 150, editable: true },
    {
      field: "Balance",
      headerName: "Balance",
      width: 150,
      editable: true,
    },
  ];

  const inputField = (label, name, readonly = false, required = false) => {
    return (
      <TextField
        id={name}
        name={name}
        label={label}
        required={required}
        variant="outlined"
        size="small"
        className="flex-fill"
        value={loanData[name]}
        onChange={handleChange}
        InputProps={{ readOnly: readonly, disableUnderline: true }}
      />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let submitData = {
      TRANSACIONID: loanData.TRANSACIONID,
      CIFKEY: loanData.CIFKEY,
      Mode: loanData.Mode,
      Reference: loanData.Reference,
      LoanCat: loanData.LoanCat,
      LoanAmount: parseFloat(loanData.LoanAmount),
      Offset: parseFloat(loanData.Offset),
      NetLoan: parseFloat(loanData.NetLoan),
      NOF: parseInt(loanData.NOF),
      Interest: parseFloat(loanData.Interest),
      Repayable: parseFloat(loanData.Repayable),
      Deduction: parseFloat(loanData.Deduction),
      SalesCode: loanData.SalesCode,
      PNO: loanData.PNO,
      PPE: loanData.PPE,
    };
    console.log("submitting...", submitData);

    let response;
    if (state) {
      response = await axiosPrivate.put("/loans/updateLoan", loanData);
      console.log("Update Loan Returned:", response);
    } else {
      response = await axiosPrivate.post("/loans/addLoan", submitData);
      console.log("Add Loan Returned:", response);
    }

    navigate("/loanList");
  };

  useEffect(() => {
    const fetchData = async () => {
      let response;
      response = await axiosPrivate.get("/clients");
      console.log("All Client result:", response);
      setAllClients(response.data);

      response = await axiosPrivate.get("/loans/getLoanCategories");
      console.log("All loan categories result:", response);
      setLoanCategories(response.data);

      // result = await getGqlData(Constants.USERS_QUERY);
      // console.log("All users result:", result);
      // setUsers(result.users);

      response = await axiosPrivate.get("/modes");
      console.log("All modes result:", response);
      setModes(response.data);
    };

    fetchData();
  }, []);

  const clientNameProps = {
    options: allClients.map((option) => option.ClientName),
  };
  const cifkeyProps = {
    options: allClients.map((option) => option.CIFKEY),
  };
  const loanCatProps = {
    options: loanCategories.map((option) => option.LoanCat),
  };
  const salesCodeProps = {
    options: users.map((option) => option),
  };
  const modeProps = {
    options: modes.map((option) => option.Mode),
  };
  const onCnChange = (event, values) => {
    //console.log("Auto", event, values);
    setLoanData((prevData) => ({
      ...prevData,
      ClientName: values,
    }));
    let found = allClients.find((o) => {
      return values === o.ClientName;
    });
    if (found) {
      setLoanData((prevData) => ({
        ...prevData,
        CIFKEY: found.CIFKEY,
      }));
    }
  };

  const onCifChange = (event, values) => {
    setLoanData((prevData) => ({
      ...prevData,
      CIFKEY: values,
    }));
    let found = allClients.find((o) => {
      return values === o.CIFKEY;
    });
    if (found) {
      setLoanData((prevData) => ({
        ...prevData,
        ClientName: found.ClientName,
      }));
    }
  };

  const onLoanCatChange = (event, values) => {
    console.log(event);
    setLoanData((prevData) => ({
      ...prevData,
      LoanCat: values,
    }));
  };

  const onSalesCodeChange = (event, values) => {
    console.log(event);
    setLoanData((prevData) => ({
      ...prevData,
      SalesCode: values,
    }));
  };

  const onModeChange = (event, values) => {
    console.log("Mode value:", values);
    setLoanData((prevData) => ({
      ...prevData,
      Mode: values,
    }));
  };

  return (
    <Stack
      spacing={2}
      ml={2}
      maxWidth="xs"
      sx={{ width: "100%" }}
      alignItems="left"
    >
      <form onSubmit={handleSubmit} style={{ width: "95%" }}>
        <div className="m-2 d-flex flex-row justify-content-between">
          <h4>{state ? "Update Loan" : "Create New Loan"}</h4>
          <Button
            disabled={
              !loanData.CIFKEY ||
              !loanData.Repayable ||
              (!state &&
                (!loanData.PNO ||
                  !loanData.PPE ||
                  !loanData.LoanCat ||
                  !loanData.SalesCode ||
                  !loanData.Mode))
            }
            variant="contained"
            type="submit"
            size="small"
          >
            Submit
          </Button>
        </div>
        <div className="d-flex flex-row w-100 gap-3 align-items-center mb-3">
          <Autocomplete
            {...clientNameProps}
            disableClearable
            required
            readOnly={state !== null}
            id="ClientName"
            name="ClientName"
            value={loanData.ClientName}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option === value
            }
            className="flex-fill"
            onChange={onCnChange}
            renderInput={(params) => (
              <TextField
                {...params}
                value={loanData.ClientName}
                label="Client Name"
                variant="outlined"
                size="small"
                readOnly
              />
            )}
          />

          <Autocomplete
            {...cifkeyProps}
            readOnly={state !== null}
            disableClearable
            value={loanData.CIFKEY}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option === value
            }
            id="CIFKEY"
            name="CIFKEY"
            className="flex-fill"
            onChange={onCifChange}
            renderInput={(params) => (
              <TextField
                {...params}
                value={loanData.CIFKEY}
                label="CIFKEY"
                variant="outlined"
                size="small"
              />
            )}
          />
        </div>

        <div className="d-flex flex-row w-100 gap-3 align-items-center mb-2">
          <Autocomplete
            {...loanCatProps}
            disableClearable
            value={loanData.LoanCat}
            required={state === null}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option === value
            }
            id="LoanCat"
            name="LoanCat"
            className="flex-fill"
            onChange={onLoanCatChange}
            renderInput={(params) => (
              <TextField
                {...params}
                value={loanData.LoanCat}
                label="Loan Category"
                variant="outlined"
                size="small"
              />
            )}
          />

          <TextField
            id="LoanAmount"
            name="LoanAmount"
            label="Loan Amount"
            required
            variant="outlined"
            size="small"
            className="flex-fill"
            value={loanData.LoanAmount}
            onChange={handleChange}
            type="number"
            InputProps={{
              inputProps: {
                max: 10000,
                min: 300,
              },
            }}
          />
          <TextField
            id="NOF"
            name="NOF"
            label="No Of Fortnight"
            required
            variant="outlined"
            size="small"
            className="flex-fill"
            value={loanData.NOF}
            onChange={handleChange}
            type="number"
            InputProps={{
              inputProps: {
                max: 26,
                min: 4,
              },
            }}
          />
          {/* {inputField("Loan Amount", "LoanAmount", false, true)} */}
          {/* {inputField("No Of Fortnight", "NOF", false, true)} */}
        </div>
        <div className="d-flex flex-row gap-3 my-3 justify-content-between align-items-center">
          {inputField("Offset", "Offset")}
          {inputField("Interest", "Interest", true, true)}
          {inputField("Repayable", "Repayable", false, true)}
        </div>
        <div className="d-flex flex-row gap-3 my-3 justify-content-between align-items-center">
          {inputField("Net Loan", "NetLoan", true, false)}

          {inputField("Deduction", "Deduction", true, false)}
          <Autocomplete
            {...salesCodeProps}
            disableClearable
            value={loanData.SalesCode}
            required={state === null}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option === value
            }
            id="SalesCode"
            name="SalesCode"
            className="flex-fill"
            onChange={onSalesCodeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                value={loanData.SalesCode}
                label="Sales Associate"
                variant="outlined"
                size="small"
              />
            )}
          />
        </div>
        <div className="d-flex flex-row gap-3 my-3 justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center">
            <span>First PNO: </span>
            <Select
              className="flex-fill"
              value={parseInt(loanData.PNO)}
              required={state === null}
              onChange={(e, value) =>
                setLoanData((prevData) => ({
                  ...prevData,
                  PNO: value,
                }))
              }
              sx={{
                size: "lg",
                width: 100,
                fontSize: 16,
                marginLeft: 1,
                marginRight: 1,
              }}
            >
              {[...Array(27)].map((_, i) => (
                <Option sx={{ fontSize: 16 }} key={i} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              className="flex-fill"
              value={dayjs(loanData.PPE)}
              required={state === null}
              onChange={(value, error) => {
                if (!error.validationError) {
                  setLoanData((prevData) => ({
                    ...prevData,
                    PPE: new Date(value)
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                  }));
                }
              }}
              size="small"
              label="First Deduction Date"
              format="YYYY-MM-DD"
            />
          </LocalizationProvider>
          <Autocomplete
            {...modeProps}
            required={state === null}
            disableClearable
            value={loanData.Mode}
            isOptionEqualToValue={(option, value) =>
              value === undefined || value === "" || option === value
            }
            id="Mode"
            name="Mode"
            className="flex-fill"
            onChange={onModeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Transaction Mode"
                variant="outlined"
                size="small"
              />
            )}
          />
        </div>
        <div className="d-flex flex-row gap-3 my-3 justify-content-between align-items-center">
          {inputField("Reference", "Reference")}

          {inputField("Remarks", "Remarks")}
        </div>
        <h5>Loan Table</h5>
        <Box mb={2} sx={{ height: 300, width: 1 }}>
          <DataGrid
            rowHeight={25}
            rows={repayments}
            getRowId={(row) => uuid()}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 700 },
              },
            }}
          />
        </Box>
      </form>
    </Stack>
  );
}

export default AddLoan;
