import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import mainLogo from "./../assets/logo512.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const defaultTheme = createTheme();

export default function SignUp() {
  const axiosPrivate = useAxiosPrivate();
  const [roles, setRoles] = React.useState(1);

  const handleChange = (event) => {
    console.log("Select event", event);
    setRoles(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userData = {
      user: data.get("username"),
      email: data.get("email"),
      pwd: data.get("password"),
      roles: roles,
    };

    // data.set("username", "");
    // data.set("email", "");
    // data.set("password", "");

    console.log("Register submitting...", userData);

    const response = await axiosPrivate.post("/register", userData);
    console.log("Register User Returned:", response);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <Box component="main" maxWidth="xs" className="m-3">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <img
              src={mainLogo}
              style={{ width: "50px", marginBottom: "30px" }}
              alt="1AVini"
            /> */}
            <Typography component="h1" variant="h5">
              Register User
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="off"
                    name="username"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="roles-label">Roles</InputLabel>
                    <Select
                      labelId="roles-label"
                      id="roles-select"
                      value={roles}
                      label="Roles"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>User</MenuItem>
                      <MenuItem value={3}>Finance</MenuItem>
                      <MenuItem value={7}>Admin</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
